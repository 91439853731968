import Vue from 'vue'
import Vuex from 'vuex'

import {triggerMobileAction} from '@/utils/webview.util'
import {PAGE_LOADING} from '@/enums/mobile-event.enum'

import auth from './modules/auth.store'
import user from './modules/user.store'
import config from './modules/config.store'
import menu from './modules/menu.store'

Vue.use(Vuex)

const state = () => ({
  loading: false,
  ajaxLoading: false,
  keyboardHeight: 0,
})

const getters = {
  loading: state => state.loading,
  ajaxLoading: state => state.ajaxLoading,
  loadingAny: state => state.loading || state.ajaxLoading,
  keyboardHeight: state => state.keyboardHeight,
}

const mutations = {
  setLoading (state, isLoading) {
    state.loading = Boolean(isLoading)
  },
  setAjaxLoading (state, isLoading) {
    state.loading = Boolean(isLoading)
  },
  setKeyboardHeight (state, height) {
    state.keyboardHeight = height
  }
}

const actions = {
  toggleLoading ({state, commit}, enable = null) {
    if (enable === null) {
      enable = !state.loading
    }
    commit('setLoading', enable)
    triggerMobileAction(PAGE_LOADING, {
      toggle: enable
    })
  }
}

export default new Vuex.Store({
  // root
  state,
  getters,
  mutations,
  actions,

  // modules
  modules: {
    auth,
    user,
    config,
    menu,
  }
})
