<template>
  <div class="notification-list">
    <div
      class="notification-unread"
    >
      <div class="title-unread">
        <p>
          {{ nl2br($t('unread')) }}
        </p>
        <div class="check__notify">
          <label class="form-check">{{ nl2br($t('mark_read')) }}
            <input
              type="checkbox"
              checked="checked"
              @change="handleCheckNotify"
            >
            <span class="checkmark">
              <i :class="{'ticked': !totalUnread}" />
            </span>
          </label>
        </div>
      </div>
      <div
        v-if="unreadLists && unreadLists.length"
        class="list-unread list-common"
      >
        <div
          v-for="item in unreadLists"
          :key="item.id"
          class="list-unread__item"
        >
          <div class="notify__icon">
            <img
              src="../../../assets/images/email_unread.svg"
              alt=""
            >
          </div>
          <div
            class="notify__content"
            @click="checkedNotify(item, 'unread')"
          >
            <p class="date">
              {{ item.published_time | formatDate('YYYY.MM.DD') }}
            </p>
            <p class="desc">
              {{ item.title }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="notification-read"
    >
      <p class="title-read">
        {{ nl2br($t('read')) }}
      </p>
      <div
        v-if="readLists && readLists.length"
        class="list-read list-common"
      >
        <div
          v-for="item in readLists"
          :key="item.id"
          class="list-read__item"
        >
          <div class="notify__icon">
            <img
              src="../../../assets/images/email_read.svg"
              alt=""
            >
          </div>
          <div
            class="notify__content"
            @click="checkedNotify(item, 'read')"
          >
            <p class="date date__read">
              {{ item.published_time | formatDate('YYYY.MM.DD') }}
            </p>
            <p class="desc">
              {{ item.title }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {addEventListener, removeEventListener, triggerMobileAction} from '@/utils/webview.util'
import {USER_TOKEN} from '@/enums/web-event.enum'
import {nl2br} from '@/utils/string.util'
import {NotificationService} from '@/services/notification.service'
import {OPEN_NOTIFICATION} from '@/enums/mobile-event.enum'
import { mapGetters } from 'vuex'

export default {
  name: 'Notification',
  mixins: [WebviewMixin],
  data () {
    return {
      totalUnread: 0,
      unreadLists: [],
      readLists: []
    }
  },
  computed: {
    ...mapGetters({
      hasToken: 'auth/hasToken',
    }),
  },
  created () {
    if (this.hasToken) {
      this.initData()
    } else {
      addEventListener(USER_TOKEN, this.initData)
    }
  },
  beforeDestroy () {
    removeEventListener(USER_TOKEN, this.initData)
  },
  methods: {
    nl2br,
    initData (e) {
      if (e?.detail?.token || this.hasToken) {
        this.handleApiNotify()
      }
    },
    async handleApiNotify () {
      try {
        const [read, unread, count] = await Promise.all([
          NotificationService.getListNotifyRead(),
          NotificationService.getListNotifyUnread(),
          NotificationService.countNotifyUnread()
        ])

        this.readLists = read
        this.unreadLists = unread
        this.totalUnread = count
      } catch (e) {
        throw e
      }
    },
    async handleCheckNotify () {
      if (this.totalUnread) {
        try {
          await NotificationService.checkReadAll()
          await this.handleApiNotify()
        } catch (e) {
          throw e
        }
      }
    },
    async checkedNotify (item, type) {
      if (type === 'unread') {
        try {
          await NotificationService.checkRead(item.id)
          this.handleApiNotify()
        } catch (e) {
          throw e
        }
      }
      this.hasLink(item)
    },

    hasLink (item) {
      if (item?.link) {
        triggerMobileAction(OPEN_NOTIFICATION, {
          url: item.link
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-check {
  display: block;
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 10px;
    width: 10px;
    background-color: #FFFFFF;
    border: 1px solid #F36045;
    border-radius: 50%;

    .ticked {
      content: " ";
      display: block;
      width: 0.3em; // the short bar of the mark is half as long as the long bar
      height: 0.6em;
      border: solid #F36045;
      border-width: 0 1px 1px 0; // creates the inverted "L" shape
      position: absolute;
      left: 3px;
      top: -1px;
      -webkit-transform: rotate(45deg); // tilts the shape
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &__icon {
    margin-left: 7px;
    width: 10.97px;
    height: 10.25px;
  }
}

.notification-list {
  padding-top: 20px;
  padding-bottom: 40px;
  display: block;

  .notification-unread {
    width: 100%;
    margin-bottom: 23px;
    .title-unread {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 6px;
      border-bottom: 1px solid #EAEAEA;

      p {
        font-size: 10px;
        color: #F7B0A1;
      }
      .check__notify {
        display: flex;
        justify-content: flex-end;

        .form-check {
          color: #F56B4E;
          font-size: 12px;
          line-height: unset;
          margin-bottom: 4px;
          padding-left: 16px;
        }
      }
    }
    .list-common {
      overflow-y: auto;
      height: 100%;
    }
  }

  .notification-read {
    .title-read {
      font-size: 10px;
      color: #CCCCCC;
      border-bottom: 1px solid #EAEAEA;
      padding-bottom: 6px;
      padding-top: 20px;
    }
    .list-read {
      overflow-y: auto;
      height: 100%;
    }
  }
}

.list-common {
  padding-top: 10px;

  .list-unread__item, .list-read__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 28px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .notify__icon {
    padding: 4px 7px 2px 2px;

    img {
      width: 14px;
    }
  }
  .notify__content {
    line-height: 20px;
    text-decoration: none;
    width: 100%;
    .date {
      font-size: 12px;
      color: #AFAFAF;

      &__read {
        color: #CCCCCC;
      }
    }
    .desc {
      font-size: 14px;
      color: #555555;
      font-weight: bold;
    }
  }
}
</style>
