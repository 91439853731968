import Vue from 'vue'
import VueI18n from 'vue-i18n'

// import languages
import ja from '@/languages/ja'
import en from '@/languages/en'

// change language listener
import {addEventListener} from '@/utils/webview.util'
import {CHANGE_LANGUAGE} from '@/enums/web-event.enum'

Vue.use(VueI18n)

const messages = {
  ja: {...ja},
  en: {...en}
}

const i18n = new VueI18n({
  locale: 'ja', // set locale
  messages,
  fallbackLocale: 'ja'
})

addEventListener(CHANGE_LANGUAGE, (e) => {
  console.log({
    timestamp: Date.now(),
    dataForWeb: e
  })
  let {detail: {locale}} = e
  if (locale && messages[locale]) {
    i18n.locale = locale
  }
})

export default i18n
