<template>
  <div class="tax-email-provide-notice screen-body--bg">
    <div class="page-body">
      <div class="tax-email-provide-notice__text">
        {{ $t('desc_tax_email_provide_notice_1') }}
        <br>
        {{ $t('desc_tax_email_provide_notice_2') }}
        <br>
        <br>
        <br>
        {{ $t('desc_tax_email_provide_notice_3') }}
        <br>
        {{ $t('desc_tax_email_provide_notice_4') }}
        <br>
        {{ $t('desc_tax_email_provide_notice_5') }}
      </div>
    </div>
    <div class="page-footer">
      <div class="tax-email-provide-notice__submit">
        <router-link
          :to="{name: Page.ChangeEmailEmail, query: {type: EmailChangeType.TAX}}"
          custom
        >
          <button class="btn btn-primary">
            {{ $t('btn_enter_email') }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import * as EmailChangeType from '@/enums/email-change-type.enum'

export default {
  name: 'TaxEmailProvideNotice',
  mixins: [WebviewMixin],
  data () {
    return {
      EmailChangeType
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>

.tax-email-provide-notice {
  font-size: 14px;
  padding: 30px 20px 16px;

  &__text {
    line-height: 24px;
    color: #555555;

    &:first-child {
      margin-bottom: 24px;
    }
  }

  .btn {
    border-radius: 5px;
  }
}
</style>
