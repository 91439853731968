import { render, staticRenderFns } from "./ConfigSecurity.vue?vue&type=template&id=38ee27d0&scoped=true&"
import script from "./ConfigSecurity.vue?vue&type=script&lang=js&"
export * from "./ConfigSecurity.vue?vue&type=script&lang=js&"
import style0 from "./ConfigSecurity.vue?vue&type=style&index=0&id=38ee27d0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38ee27d0",
  null
  
)

export default component.exports