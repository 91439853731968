import {triggerMobileAction} from '@/utils/webview.util'
import {AUTHEN_SUCCESS} from '@/enums/mobile-event.enum'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      hasPlatform: 'config/hasPlatform',
    })
  },
  methods: {
    onAuthenticated ({token, authenticated_step = null}, isLoggingIn = false) {
      // Set new token for later requests
      this.$store.commit('auth/setToken', {token})

      this.$router.replace({
        name: this.$route.name,
      }).catch(() => {
        // do nothing because redirect to same route will throw an error
      }).finally(() => {
        // If step = 4, it's mean user need to enter customer code
        if (authenticated_step === 4) {
          this.$router.push({ name: (isLoggingIn ? this.Page.LoginCustomerCode : this.Page.RegisterCustomerCode) })
        } else if (!this.hasPlatform) {
          this.$router.push({ name: this.Page.UserMyMenu })
        } else {
          // Sent new token to mobile
          triggerMobileAction(AUTHEN_SUCCESS, {token})
        }
      })
    }
  }
}
