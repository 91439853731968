<template>
  <div class="tax-document-submission">
    <div v-html="nl2br($t('desc_tax_contact_methods_header'))" />
    <br>
    <div class="tax-document-submission__content">
      <div class="tax-document-submission__method">
        <div>{{ $t('desc_tax_contact_method_google_drive_header', {customerName: currentUser.fullname}) }}</div>
        <div class="tax-document-submission__method__content">
          <a
            class="link--darker"
            href="javascript:void(0)"
            @click="openLinkInBrowser(currentUser.document_submission_url)"
          >
            {{ $t('desc_tax_contact_method_google_drive_url') }}
          </a>
        </div>
      </div>

      <div class="tax-document-submission__method">
        <div>
          {{ $t('desc_tax_contact_method_email_header') }}
        </div>
        <div class="tax-document-submission__method__content">
          {{ $t('desc_tax_contact_method_email_desc') }}
        </div>
        <div class="tax-document-submission__method__content">
          <a
            class="link--darker"
            href="javascript:void(0)"
            @click="openLinkInBrowser('mailto:support@trusty-partners.co.jp')"
          >
            support@trusty-partners.co.jp
          </a>
        </div>
      </div>

      <div class="tax-document-submission__method">
        <div>{{ $t('desc_tax_contact_method_address_header') }}</div>
        <div
          class="tax-document-submission__method__content"
          v-html="nl2br($t('desc_tax_contact_method_address_content'))"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {nl2br} from '@/utils/string.util'
import {triggerMobileAction} from '@/utils/webview.util'
import {OPEN_BROWSER} from '@/enums/mobile-event.enum'
import {mapGetters} from 'vuex'

export default {
  name: 'TaxDocumentSubmission',
  computed: {
    ...mapGetters({
      hasPlatform: 'config/hasPlatform',
      currentUser: 'auth/currentUser',
    })
  },
  methods: {
    nl2br,
    openLinkInBrowser (url) {
      if (!url) {
        return
      }
      if (this.hasPlatform) {
        triggerMobileAction(OPEN_BROWSER, {
          url
        })
      } else {
        window.location.href = url
      }
    },
  },
}
</script>

<style scoped lang="scss">
.tax-document-submission {
  &__contact {
    font-size: 14px;

    &__content {
      font-size: 10px;
      margin-bottom: 8px;
      color: #555555;
    }
  }

  &__method {
    margin-bottom: 10px;
    line-height: 1.5;

    &__content {
      padding-left: 15px;
    }
  }
}
</style>
