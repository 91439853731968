import {ApiService} from './api.service'
import {createUser} from '@/models/user.model'

export const UserService = {
  verifyPasswordToChangeEmail ({password}) {
    const body = {
      password
    }
    return ApiService.post('/user/change_email/verify_password', body)
      .then(res => res.data)
  },

  sendNewEmailOTP ({email}) {
    const body = {
      email
    }
    return ApiService.put('/user/change_email', body)
      .then(res => res.data)
  },

  resendNewEmailOtp () {
    return ApiService.put('/user/change_email/resend_otp')
      .then(res => res.data)
  },

  verifyOTP ({otp}) {
    const body = {
      otp
    }
    return ApiService.put('/user/change_email/otp', body)
      .then(res => res.data)
  },

  verifyPasswordToChangePassword ({password}) {
    const body = {
      password
    }
    return ApiService.post('/user/change_password/verify_password', body)
      .then(res => res.data)
  },

  saveNewPassword ({password}) {
    const body = {
      password
    }
    return ApiService.put('/user/change_password', body)
      .then(res => res.data)
  },

  getUserInfo () {
    return ApiService.get('/user')
      .then(res => {
        res.data.data = createUser(res.data?.data)
        return res.data
      })
  },

  updateUserInfo ({fullname}) {
    const body = {
      fullname
    }
    return ApiService.put('/user', body)
      .then(res => res.data)
  }
}
