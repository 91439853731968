import i18n from '@/plugins/i18n'

/**
 * Validate email format
 * @param email
 * @returns {VueI18n.TranslateResult|null}
 */
const validateEmail = (email) => {
  let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (regex.test(email)) {
    return null
  }
  return i18n.t('validation_email')
}

/**
 * Validate password: 8 - 64 characters, half-width lowercase, uppercase, half-width number, special character !_/`()+-=$#&@~
 * @param password
 * @returns {VueI18n.TranslateResult|null}
 */
const validatePassword = (password) => {
  if (!password || password.length < 8) {
    return i18n.t('validation_password_min')
  }
  if (password.length > 64) {
    return i18n.t('validation_password_max')
  }

  let regex = /^([0-9a-zA-Zｧ-ﾝﾞﾟ!_/`()+\-=$#&@~])+$/
  if (!regex.test(password)) {
    return i18n.t('validation_password_character')
  }
  return null
}

const validatePasswordConfirm = (passsword, passwordConfirm) => {
  if (!passsword || passsword === passwordConfirm) {
    return null
  }
  return i18n.t('validation_password_confirm')
}

export {
  validateEmail,
  validatePassword,
  validatePasswordConfirm
}
