import * as FirebaseService from '@/services/firebase.service'
import {AuthService} from '@/services/auth.service'
import {createUser} from '@/models/user.model'
import ErrorHandler from '@/errors/handler'
import {RequestError} from '@/errors/request.error'

export default {
  methods: {
    async firebase_init () {
      FirebaseService.init({locale: this.$i18n.locale})

      await FirebaseService.onReady()
      try {
        let authorize = await FirebaseService.getRedirectResult()
        if (authorize) {
          // save user birthday info
          this.$store.commit('auth/setCurrentUser', createUser({birthday: authorize.birthday}))

          // send token to server
          let loginResult = await AuthService.loginWithSns({
            token: authorize.accessToken,
            email: authorize.userInfo.email
          })
          this.firebase_onAuthenticated(loginResult.data)
        }
      } catch (e) {
        if (e instanceof RequestError) {
          ErrorHandler.triggerError(e.message)
        } else {
          ErrorHandler.onError(e)
        }
      }
    },

    async firebase_loginWithGoogle () {
      await FirebaseService.onReady()
      FirebaseService.loginWithGoogle()
    },

    async firebase_loginWithFacebook () {
      await FirebaseService.onReady()
      FirebaseService.loginWithFacebook()
    },

    async firebase_loginWithApple () {
      await FirebaseService.onReady()
      FirebaseService.loginWithApple()
    },

    firebase_onAuthenticated (data) {
      // Do something with the response
    }
  }
}
