import axios from 'axios'
import {
  axiosConfig,
  axiosInterceptorRequestConfig,
  axiosInterceptorRequestError,
  axiosInterceptorResponseConfig,
  axiosInterceptorResponseError
} from '../config/interceptor'

const axiosInstance = axios.create(axiosConfig)

export const ApiConfig = {
  resetInterceptor () {
    // set request global
    ApiConfig.setRequestInterceptor(
      axiosInterceptorRequestConfig,
      axiosInterceptorRequestError
    )

    // set response global
    ApiConfig.setResponseInterceptor(
      axiosInterceptorResponseConfig,
      axiosInterceptorResponseError
    )
  },

  setRequestInterceptor (onFulfilled, onRejected) {
    axiosInstance.interceptors.request.use(
      onFulfilled,
      onRejected
    )
  },

  setResponseInterceptor (onFulfilled, onRejected) {
    // set response global
    axiosInstance.interceptors.response.use(
      onFulfilled,
      onRejected
    )
  }
}

// Set global default interceptor
ApiConfig.resetInterceptor()

export const ApiService = {
  get (url, params = {}, config = {}) {
    return axiosInstance.get(`${url}`, { params, ...config })
  },

  post (url, body, config = {}) {
    return axiosInstance.post(`${url}`, body, config)
  },

  put (url, body, config = {}) {
    return axiosInstance.put(`${url}`, body, config)
  },

  delete (url, config = {}) {
    return axiosInstance.delete(url, config)
  }
}
