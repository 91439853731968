import firebase from 'firebase/app'
import 'firebase/auth'
import {get} from 'lodash-es'
import {createUser} from '@/models/user.model'
import ErrorHandler from '@/errors/handler'
import {FirebaseAuthError} from '@/errors/firebase-auth.error'
import {triggerMobileAction} from '@/utils/webview.util'
import {PAGE_REDIRECT_EXTERNAL} from '@/enums/mobile-event.enum'
import i18n from '@/plugins/i18n'

const init = ({locale}) => {
  // check if app is already init
  if (firebase.apps.length !== 0) {
    return
  }

  // Auth domain config, because of iOS 16.1+ prevent to use iframe on different domain to get cookie
  // so we need to config different firebase authDomain for each domain
  // if current domain is primary domain, use firebase as authDomain
  // if current domain is secondary domain, use secondary domain as authDomain
  const useSecondaryDomain = document.domain === process.env.VUE_APP_FIREBASE_AUTH_SECONDARY_DOMAIN
  const authDomain = useSecondaryDomain ? process.env.VUE_APP_FIREBASE_AUTH_SECONDARY_DOMAIN : process.env.VUE_APP_FIREBASE_AUTH_DOMAIN

  // web app's Firebase configuration
  var firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: authDomain,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
  }
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig)

  // Language code list: https://github.com/firebase/firebaseui-web/blob/master/LANGUAGES.md
  firebase.auth().languageCode = locale === 'en' ? 'en' : 'ja'
}

const onReady = () => {
  return new Promise((resolve) => {
    let firebaseReady = () => {
      if (firebase.apps && firebase.apps.length) {
        resolve()
      } else {
        setTimeout(firebaseReady, 500)
      }
    }
    firebaseReady()
  })
}

const $_handleError = (errorCode, canRetry = false) => {
  let message = ''
  switch (errorCode) {
    case 'auth/user-cancelled':
      // do nothing
      return
    case 'auth/account-exists-with-different-credential':
    case 'auth/credential-already-in-use':
    case 'auth/email-already-in-use':
      message = i18n.t('error_account_register')
      break
    case 'auth/auth-domain-config-required':
    case 'auth/operation-not-supported-in-this-environment':
    case 'auth/timeout':
    default:
      message = i18n.t('has_error')
      break
  }
  ErrorHandler.onError(new FirebaseAuthError({errorCode, message}))
}

const $_getGoogleAuthProvider = () => {
  // Create an instance of the Google provider object
  let provider = new firebase.auth.GoogleAuthProvider()

  // Optional: Specify additional OAuth 2.0 scopes that you want to request from the authentication provider
  // See more: https://developers.google.com/identity/protocols/oauth2/scopes?authuser=0
  // provider.addScope('https://www.googleapis.com/auth/user.birthday.read')
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')

  return provider
}

const $_getFacebookAuthProvider = () => {
  // Create an instance of the Google provider object
  let provider = new firebase.auth.FacebookAuthProvider()

  // Optional: Specify additional OAuth 2.0 scopes that you want to request from the authentication provider
  // See more: https://developers.facebook.com/docs/permissions/reference
  // provider.addScope('user_birthday')

  return provider
}

const loginWithGoogle = () => {
  let provider = $_getGoogleAuthProvider()

  triggerMobileAction(PAGE_REDIRECT_EXTERNAL, {})

  firebase.auth().signInWithRedirect(provider)
}

const loginWithFacebook = () => {
  let provider = $_getFacebookAuthProvider()

  triggerMobileAction(PAGE_REDIRECT_EXTERNAL, {})

  firebase.auth().signInWithRedirect(provider)
}

const loginWithApple = () => {
  var provider = new firebase.auth.OAuthProvider('apple.com')

  triggerMobileAction(PAGE_REDIRECT_EXTERNAL, {})

  provider.addScope('email')
  provider.addScope('name')

  firebase.auth().signInWithRedirect(provider)
}

const getRedirectResult = () => {
  return new Promise((resolve, reject) => {
    firebase.auth()
      .getRedirectResult()
      .then((res) => {
        if (!res.credential) {
          return resolve(null)
        }

        let accessToken
        let userInfo

        switch (res.credential.providerId) {
          case 'facebook.com':
            accessToken = get(res, 'user.za')

            let birthday = get(res, 'additionalUserInfo.profile.birthday')
            if (birthday) {
              /**
               * As describe in https://developers.facebook.com/docs/graph-api/reference/user/
               * birthday default format MM/DD/YYYY, but user can control return value to only year YYYY or only month/day MM/DD
               */
              let birthdayArr = `${birthday}`.split('/')
              if (birthdayArr.length === 1) {
                // only year case
                birthday = `${birthdayArr[0]}-01-01`
              } else if (birthdayArr.length === 2) {
                // only month and day case
                birthday = `${new Date().getFullYear()}-${birthdayArr[1]}-${birthdayArr[0]}`
              } else if (birthdayArr.length === 3) {
                // default
                birthday = `${birthdayArr[2]}-${birthdayArr[1]}-${birthdayArr[0]}`
              } else {
                // If the birthday format is not correct, it is better not to have it
                birthday = null
              }
            }
            userInfo = createUser({
              birthday
            })
            break
          case 'google.com':
            accessToken = get(res, 'user.za')
            userInfo = createUser({
              email: get(res, 'additionalUserInfo.profile.email')
            })
            break
          case 'apple.com':
            accessToken = get(res, 'user.za')
            userInfo = createUser({})
            break
        }

        resolve({
          accessToken,
          userInfo
        })
      })
      .catch((error) => {
        let {code, ...data} = error
        $_handleError(code, data)
        reject(error)
      })
  })
}

export {
  init,
  onReady,
  loginWithGoogle,
  loginWithFacebook,
  loginWithApple,
  getRedirectResult
}
