<template>
  <div
    id="overlay"
    :class="{'show': Object.keys(description).length > 0}"
    class="tax-desc"
    @click.self="closePopup"
  >
    <div class="tax-desc__content">
      <p class="title">
        {{ description.content }}
      </p>
      <p
        class="desc"
        v-html="description.note"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalTaxDesc',
  props: {
    description: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  methods: {
    closePopup () {
      this.$emit('update:description', {})
    }
  }
}
</script>

<style lang="scss" scoped>
.tax-desc {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 2;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all ease-out .15s;
  &.show {
    visibility: visible;
    opacity: 1;
  }
  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    background-color: #000000;
    opacity: .7;
    padding: 17px 20px 30px;
    .title {
      font-size: 16px;
      margin-bottom: 8px;
      color: #FFFFFF;
    }
    .desc {
      white-space: pre-line;
      font-size: 12px;
      line-height: 18px;
      color: #FFFFFF;
    }
  }
}
</style>
