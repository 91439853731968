import * as UserRegisterType from '@/enums/user-register-type.enum'

export class UserModel {
  constructor ({
    email = null,
    birthday = null,
    fullname = null,
    uid = null,
    type = null,
    email_sns = null,
    customer_code = null,
    document_submission_url = null,
  } = {}) {
    this.email = email
    this.fullname = fullname
    this.birthday = birthday
    this.uid = uid
    this.type = type
    this.email_sns = email_sns
    this.customer_code = customer_code || '-'
    this.document_submission_url = document_submission_url || null

    this.canChangeEmail = !!email_sns || !!email
    this.canChangePassword = type !== UserRegisterType.SNS
    this.hasEmail = !!email_sns || !!email
  }
}

/**
 * create a readonly instant of UserModel
 * @param data
 * @returns {Readonly<UserModel>}
 */
export function createUser (data) {
  return Object.freeze(
    new UserModel(data)
  )
}
