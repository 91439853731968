/**
 * Replace newline with html tag <br/> to breakline in html
 * @param str source string
 * @param is_xhtml replace with <br/> or <br>
 * @returns {string}
 */
const nl2br = (str, is_xhtml = false) => {
  if (!str) return ''

  let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

/**
 * Replace newline with html tag div with inline-block style to break line in html
 * @param str source string
 * @returns {string}
 */
const nl2div = (str) => {
  if (!str) return ''

  let strList = (str + '').split(/\n/g)

  return strList.map((str) => {
    return `<div style="display: inline-block;">${str}</div>`
  }).join('')
}

/**
 * replace all search with replace
 * Trying to imitate https://www.php.net/manual/en/function.str-replace.php
 * @param {String[]|String} search The value being searched for, otherwise known as the needle. An array may be used to designate multiple needles.
 * @param {String[]|String} replace The replacement value that replaces found search values. An array may be used to designate multiple replacements.
 * @param {String} subject source string The string or array being searched and replaced on, otherwise known as the haystack.
 * @returns {*}
 */
const replaceAll = (search, replace, subject) => {
  if (!Array.isArray(search)) {
    search = [search]
  }

  let replaceString = (search, replace, subject) => {
    return subject.replace(new RegExp(search, 'g'), replace)
  }

  search.forEach((searchItem, index) => {
    if (!Array.isArray(search)) {
      subject = replaceString(searchItem, replace, subject)
    } else {
      subject = replaceString(searchItem, replace.hasOwnProperty(index) ? replace[index] : '', subject)
    }
  })

  return subject
}

/**
 * Get a query from url, if key is not passed, get all query from url
 *
 * @param url
 * @param key
 * @returns {string|null|*[]|{}}
 */
const getUrlQueries = (url, key = null) => {
  let tmp = url.split('?')
  if (!tmp.length || tmp.length < 2) {
    return key ? null : []
  }
  tmp = tmp[1]
  let queries = tmp.split('&')
  let result = {}
  for (let i = 0; i < queries.length; i++) {
    let query = queries[i]
    query = query.split('=')
    if (key && query[0] === key) {
      return typeof query[1] !== 'undefined' ? unescape(query[1]) : null
    }
    result[query[0]] = query.length !== 2 ? null : unescape(query[1])
  }
  return result
}

export {
  nl2br,
  nl2div,
  replaceAll,
  getUrlQueries
}
