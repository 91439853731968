<template>
  <div class="footer">
    <ol class="footer__nav">
      <li class="footer__nav__item">
        <button
          class="btn-text"
          @click="openExternalLink(appInfo.contact_us, $t('nav_contact_us'))"
        >
          {{ $t('nav_contact_us') }}
        </button>
      </li>
      <li class="footer__nav__item">
        <button
          class="btn-text"
          @click="openExternalLink(appInfo.operate_company, $t('nav_company_info'))"
        >
          {{ $t('nav_company_info') }}
        </button>
      </li>
      <li class="footer__nav__item">
        <button
          class="btn-text"
          @click="openExternalLink(appInfo.term_of_service, $t('nav_term_of_service'))"
        >
          {{ $t('nav_term_of_service') }}
        </button>
      </li>
      <li class="footer__nav__item">
        <button
          class="btn-text"
          @click="openExternalLink(appInfo.privacy_policy, $t('nav_privacy_policy'))"
        >
          {{ $t('nav_privacy_policy') }}
        </button>
      </li>
    </ol>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {openExternalLink} from '@/utils/webview.util'

export default {
  name: 'AuthFooter',
  computed: mapGetters({
    appInfo: 'config/appInfo'
  }),
  created () {
    this.$store.dispatch('config/getAppGeneralInfo')
  },
  methods: {
    openExternalLink,
  }
}
</script>

<style
  scoped
  lang="scss"
>
.footer {
  display: flex;
  align-items: center;
  padding: 0 14px;
  &__nav {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    list-style: none;
    &__item {
      color: var(--color-footer-nav);
      line-height: 16px;
      font-size: 12px;

      @media screen and (max-width: 374px) {
        font-size: 10px;
      }

      & + &::before {
        float: left;
        padding-right: .5rem;
        padding-left: .5rem;
        color: #6c757d;
        content: '|';
      }
    }
  }

  .btn-text {
    color: $color-default-darkest;
    font-size: 3.2vw;
    @media (max-width: 335px){
      font-size: 3vw
    }
  }
}
</style>
