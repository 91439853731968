var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container screen-container",class:{
    'with-header': _vm.metaStack.hasHeader,
    'with-footer': _vm.metaStack.hasFooter,
    ios: _vm.platform === _vm.EPlatform.IOS,
    android: _vm.platform === _vm.EPlatform.ANDROID
  },attrs:{"lang":_vm.$i18n.locale}},[_c('div',{staticClass:"screen-wrapper"},[(_vm.metaStack.hasHeader)?_c('router-view',{key:"Header",staticClass:"screen-header",attrs:{"name":"header"}}):_vm._e(),_c('div',{key:"Body",staticClass:"screen-body",class:{
        'with-header': _vm.metaStack.hasHeader,
        'with-footer': _vm.metaStack.hasFooter,
        'screen-body--stretch': _vm.metaStack.isStretchBody
      }},[_c('router-view')],1),(_vm.metaStack.hasFooter)?_c('router-view',{key:"Footer",staticClass:"screen-footer",attrs:{"name":"footer"}}):_vm._e()],1),_c('div',{style:(_vm.keyboardSpaceStyle)})])}
var staticRenderFns = []

export { render, staticRenderFns }