import {ApiService} from './api.service'

export const AuthService = {

  /**
   * Login with SNS access token
   * @param token
   * @param email
   * @returns {Promise<AxiosResponse<any>>}
   */
  loginWithSns ({token, email} = {}) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    const body = {}
    if (email) {
      body.email = email
    }
    return ApiService.post('/user/login/sns', body, config)
      .then(res => res.data)
  },

  /**
   * Login with SNS passcode
   * @param id
   * @param passcode
   * @returns {Promise<AxiosResponse<any>>}
   */
  loginWithSnsPasscode ({id, passcode} = {}) {
    return ApiService.post('/user/login/sns/passcode', {id, passcode}, {})
      .then(res => res.data)
  },

  register ({email} = {}) {
    const body = {
      email
    }
    const config = {
      headers: {
        Authorization: null
      }
    }
    return ApiService.post('/user/register/email', body, config)
      .then(res => res.data)
  },

  registerSubmitOTP ({otp, email} = {}) {
    const body = {
      otp,
      email
    }
    return ApiService.post('/user/register/otp', body)
      .then(res => res.data)
  },

  registerResendOTP ({email}) {
    const body = {
      email
    }
    return ApiService.post('/user/register/email/resend_otp', body)
      .then(res => res.data)
  },

  registerPassword ({password}) {
    const body = {
      password
    }
    return ApiService.post('/user/register/password', body)
      .then(res => res.data)
  },

  submitCustomerCode ({customerCode, birthday}) {
    const body = {
      customer_code: customerCode,
      birthday
    }
    return ApiService.put('/user/register/customer_code', body)
      .then(res => res.data)
  },

  login ({email, password}) {
    const body = {
      email,
      password
    }
    const config = {
      headers: {
        Authorization: null
      }
    }
    return ApiService.put('/user/login', body, config)
      .then(res => res.data)
  },

  sendForgotPasswordEmail ({email, birthday}) {
    const body = {
      email,
      birthday
    }
    const config = {
      headers: {
        Authorization: null
      }
    }
    return ApiService.put('/user/forgot_password/input_email', body, config)
      .then(res => res.data)
  },

  createAppleLoginSession () {
    return ApiService.post('/login/sns/session')
  }
}
