<template>
  <div class="tax-document-checking screen-body--bg">
    <div class="page-body">
      <div class="tax-document-checking__header">
        <div
          class="tax-document-checking__mail-notice"
          v-html="nl2br($t('desc_tax_contact_send_mail_notice'))"
        />
        <br>

        <tax-document-submission style="margin-bottom: 30px" />

        <div class="tax-document-checking__title">
          {{ nl2br($t('prepared', { day, month, year })) }}
        </div>
        <div class="tax-document-checking__desc">
          {{ nl2br($t('following_materials')) }}
        </div>
        <div class="tax-document-checking__checkall">
          <label class="form-check">
            <span>{{ $t('check_all') }}</span>
            <input
              v-model="selectAll"
              type="checkbox"
              checked="checked"
              @change="handleCheckAll"
            >
            <span class="checkbox" />
          </label>
        </div>
      </div>
      <ul class="tax-document-checking__list screen-body--bg">
        <li
          v-for="item in data"
          :key="item.id"
        >
          <label class="form-check">
            <span>
              {{ item.content }}
              <div
                v-if="item.note"
                class="tax-document-checking__list__info"
                @click="showDescription(item)"
              >
                <div>
                  <img
                    class="form-check__icon"
                    src="/images/icons/icon-ionic-ios-information-circle.svg"
                    alt="icon-ionic-ios-information-circle"
                  >
                </div>
              </div>
            </span>
            <input
              v-model="selected"
              type="checkbox"
              :disabled="Object.keys(desc).length > 0"
              :value="item.id"
              :checked="item.checked"
              @change="selectDocument(item)"
            >
            <span class="checkbox" />
          </label>
        </li>
      </ul>
      <div>
        <div
          class="tax-document-checking__desc--footer"
          v-html="nl2br($t('following_materials_are_ready'))"
        />
        <tax-contact-block />
      </div>
    </div>
    <div class="page-footer">
      <div class="tax-document-checking__back">
        <button
          class="btn btn-primary"
          :disabled="!canSubmit"
          @click="onFinalTax"
          v-text="nl2br($t('confirmation_information_ready'))"
        />
      </div>
    </div>
    <!-- popup desc -->
    <ModalTaxDesc :description.sync="desc" />
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {addEventListener, removeEventListener} from '@/utils/webview.util'
import { USER_TOKEN } from '@/enums/web-event.enum'
import { mapGetters } from 'vuex'
import { nl2br } from '@/utils/string.util'
import ModalTaxDesc from '@/views/pages/Tax/components/ModalTaxDesc'
import { TaxService } from '@/services/tax.service'
import { dynamicSort } from '@/utils/common.util'
import moment from 'moment'
import TaxContactBlock from '@/views/pages/Tax/components/TaxContactBlock'
import TaxDocumentSubmission from '@/views/pages/Tax/components/TaxDocumentSubmission.vue'

export default {
  name: 'TaxDocumentChecking',
  components: { ModalTaxDesc, TaxContactBlock, TaxDocumentSubmission },
  mixins: [WebviewMixin],
  data () {
    return {
      data: [],
      selected: [],
      desc: {},
    }
  },
  computed: {
    selectAll: {
      // eslint-disable-next-line vue/return-in-computed-property
      get: function () {
        if (this.selected.length > 0 && this.data.length) {
          return this.selected.length === this.data.length
        }
        return false
      },
      set: function (value) {
        let selected = []

        if (value) {
          this.data.forEach(function (item) {
            selected.push(item.id)
          })
        }

        this.selected = selected
      },
    },
    canSubmit () {
      return this.hasToken && !this.loading && this.selected.length && this.selected.length === this.data.length
    },
    day () {
      return moment(this.$route.query.deadline_date).format('D')
    },
    month () {
      return moment(this.$route.query.deadline_date).format('M')
    },
    year () {
      return moment(this.$route.query.deadline_date).format('YYYY')
    },
    ...mapGetters({
      hasToken: 'auth/hasToken',
      hasPlatform: 'config/hasPlatform',
    }),
  },
  created () {
    addEventListener(USER_TOKEN, this.initData)
  },
  beforeDestroy () {
    removeEventListener(USER_TOKEN, this.initData)
  },
  methods: {
    nl2br,
    initData (e) {
      if (e?.detail?.token) {
        this.$store.dispatch('auth/getCurrentUserInfo')
        TaxService.getDocumentSession(this.$route.params.id).then(res => {
          const selected = []

          this.data = res
          this.data = this.data.sort(dynamicSort('desc', 'weight', 'id'))

          this.data.forEach(item => {
            if (item.checked) {
              selected.push(item.id)
            }
          })
          this.selected = selected
        })
        this.$store.dispatch('config/getAppGeneralInfo')
      }
    },
    async onFinalTax () {
      if (this.selected.length === this.data.length) {
        try {
          await TaxService.finishedDocumentSession(this.$route.params.id)
          await this.$router.replace({
            name: this.Page.TaxProgress,
            params: { ...this.$route.params },
            query: { ...this.$route.query },
          })
        } catch (e) {
          throw e
        }
      }
    },
    showDescription (item) {
      this.desc = {
        note: item.note,
        content: item.content,
      }
    },
    async handleCheckAll () {
      try {
        await TaxService.checkAllDocumentSession(this.$route.params.id, { checked: this.selectAll })
      } catch (e) {
        throw e
      }
    },
    async selectDocument (item) {
      const currentStatus = item.checked

      item.checked = !currentStatus
      const body = {
        document_id: item.id,
        checked: item.checked,
      }
      try {
        await TaxService.checkDocumentSession(this.$route.params.id, body)
      } catch (e) {
        item.checked = currentStatus
        throw e
      }
    },
  },
}
</script>

<style
    scoped
    lang="scss"
>
.tax-document-checking {
  font-size: 14px;
  color: #555555;

  .page-body {
    margin-bottom: 40px;
  }

  .page-footer {
    padding-bottom: 20px;
    background-color: #F8F8F8;
  }

  &__header {
    background-color: white;
    margin: 0 -20px;
    padding: 27px 20px 0 20px;
  }

  &__mail-notice {
    font-size: 14px;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 20px;
    padding-top: 19px;
    padding-bottom: 19px;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
  }

  &__desc {
    color: #555555;
    margin-bottom: 8px;
    line-height: 19px;

    &--footer {
      margin-bottom: 45px;
    }
  }

  &__list {
    padding: 21px 0 45px;
    background-color: #F8F8F8;

    li {
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__info {
      display: inline-block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%
      }
    }
  }

  .btn {
    border-radius: 5px;
  }

  .form-check {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    > * {
      order: 1;
    }

    .checkbox {
      margin-right: 12px;
      order: 0;
      height: 15px;
      width: 15px;
      flex-shrink: 0;
      align-self: flex-start;
      margin-top: 2.5px;
      background-image: url("/images/checkbox.svg");
      background-position: top left;
      background-size: 100%;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkbox {
        background-image: url("/images/checkbox-checked.svg");
      }
    }

    &__icon {
      width: 10.97px;
      height: 10.25px;
    }
  }

  &__checkall {
    display: flex;
    justify-content: flex-end;

    .form-check {
      color: #0AA9F4;
      font-size: 12px;
      margin-bottom: 4px;
      padding-left: 20px;

      .checkbox {
        margin-right: 7px;

        &::after {
          top: -5px;
        }
      }
    }
  }
}
</style>
