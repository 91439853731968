<template>
  <div class="config-security">
    <div class="page-body">
      <div class="config-security__title">
        {{ $t('common_login_configuration') }}
      </div>
      <div class="config-security__input">
        <div class="config-security__input__text">
          <div class="config-security__input__text__section">
            {{ $t('common_enable_device_authentication') }}
          </div>
          <div class="config-security__input__text__desc">
            {{ $t('common_enable_device_authentication_desc') }}
          </div>
        </div>
        <div class="config-security__input__toggle">
          <toggle-button
            v-model="form.enableLoginVerification"
            :sync="true"
            :color="{checked: '#24CFD6', unchecked: '#D3D3D3', disabled: '#EAEAEA'}"
            :disabled="!enable"
            :width="40"
            :height="25"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {ToggleButton} from 'vue-js-toggle-button'
import {triggerMobileAction, addEventListener, removeEventListener} from '@/utils/webview.util'
import {UPDATE_DEVICE_CONFIG} from '@/enums/mobile-event.enum'
import {DEVICE_CONFIG, USER_TOKEN} from '@/enums/web-event.enum'

export default {
  name: 'ConfigSecurity',
  components: {ToggleButton},
  mixins: [WebviewMixin],
  data () {
    return {
      enable: false,
      form: {
        enableLoginVerification: false
      }
    }
  },
  watch: {
    'form.enableLoginVerification' (val) {
      if (this.enable) {
        triggerMobileAction(UPDATE_DEVICE_CONFIG, {
          enableLoginVerification: val
        })
      }
    }
  },
  created () {
    addEventListener(DEVICE_CONFIG, this.enableInput)
  },
  beforeDestroy () {
    removeEventListener(USER_TOKEN, this.enableInput)
  },
  methods: {
    enableInput (event) {
      console.log(event.detail)
      if (event && event.detail) {
        this.form.enableLoginVerification = event.detail.enable_login_verification
      }
      this.enable = true
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
.config-security {
  font-size: 14px;
  .page-body {
    padding-top: 17px;
  }

  &__title {
    padding-bottom: 19px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 17px;
    font-size: 16px;
    font-weight: bold;
  }

  &__input {
    display: flex;

    &__text {
      white-space: nowrap;

      &__section {
        margin-bottom: 2px;
        line-height: 19px;
      }

      &__desc {
        color: #777777;
        font-size: 11px;
        line-height: 15px;
      }
    }
    &__toggle {
      width: 100%;
      text-align: right;
      padding-top: 6px;
    }
  }
}
</style>
