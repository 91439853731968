import * as Page from '@/enums/page-name.enum'
import AuthHeader from '@/views/layouts/AuthHeader'
import BlankParentNode from '@/views/layouts/BlankParentNode'
import AuthFooter from '@/views/layouts/AuthFooter'
import Index from '@/views/pages/Index'
import Login from '@/views/pages/Login/Login'
import Register from '@/views/pages/Register/Register'
import RegisterOtp from '@/views/pages/Register/RegisterOtp'
import RegisterPassword from '@/views/pages/Register/RegisterPassword'
import RegisterCustomerCode from '@/views/pages/Register/RegisterCustomerCode'
import GraphSimulation from '@/views/pages/Graph/GraphSimulation'
import GraphCashFlow from '@/views/pages/Graph/GraphCashFlow'
import Notification from '@/views/pages/Notification/Notification'
import UserMyMenu from '@/views/pages/User/UserMyMenu'
import UserDeactivation from '@/views/pages/User/UserDeactivation'
import UserChangeInfo from '@/views/pages/User/UserChangeInfo'
import ChangeEmail from '@/views/pages/ChangeEmail/ChangeEmail'
import ChangeEmailEmail from '@/views/pages/ChangeEmail/ChangeEmailEmail'
import ChangeEmailSuccess from '@/views/pages/ChangeEmail/ChangeEmailSuccess'
import ConfigSecurity from '@/views/pages/Config/ConfigSecurity'
import ConfigFontSize from '@/views/pages/Config/ConfigFontSize'
import ConfigPushNotification from '@/views/pages/Config/ConfigPushNotification'
import ForgotPassword from '@/views/pages/ForgotPassword/ForgotPassword'
import ForgotPasswordSent from '@/views/pages/ForgotPassword/ForgotPasswordSent'
import ChangeEmailOtp from '@/views/pages/ChangeEmail/ChangeEmailOtp'
import ChangePassword from '@/views/pages/ChangePassword/ChangePassword'
import ChangePasswordNew from '@/views/pages/ChangePassword/ChangePasswordNew'
import ChangePasswordSuccess from '@/views/pages/ChangePassword/ChangePasswordSuccess'
import TaxSession from '@/views/pages/Tax/TaxSession'
import TaxProgress from '@/views/pages/Tax/TaxProgress'
import TaxTrustingConfirmation from '@/views/pages/Tax/TaxTrustingConfirmation'
import TaxDocumentChecking from '@/views/pages/Tax/TaxDocumentChecking'
import TaxTrustingConfirmationForce from '@/views/pages/Tax/TaxTrustingConfirmationForce'
import TaxEmailProvideNotice from '@/views/pages/Tax/TaxEmailProvideNotice'

export const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '',
    components: {
      header: AuthHeader,
      footer: AuthFooter,
      default: BlankParentNode
    },
    meta: {
      hasHeader: true,
      hasFooter: true
    },
    children: [
      {
        path: '/login',
        name: Page.Login,
        component: Login,
        meta: {title: 'title_login'} // this key name based on `en.js`, be careful with lower/upper case
      },
      {
        path: '/login/customer_code',
        name: Page.LoginCustomerCode,
        component: RegisterCustomerCode,
        meta: {
          title: 'title_login',
          routeBack: Page.Login
        }
      },
      {
        path: '/register',
        name: Page.Register,
        component: Register,
        meta: {title: 'title_signup'}
      },
      {
        path: '/register/otp',
        name: Page.RegisterOtp,
        component: RegisterOtp,
        meta: {
          title: 'title_signup',
          routeBack: Page.Register
        }
      },
      {
        path: '/register/password',
        name: Page.RegisterPassword,
        component: RegisterPassword,
        meta: {title: 'title_signup'}
      },
      {
        path: '/register/customer_code',
        name: Page.RegisterCustomerCode,
        component: RegisterCustomerCode,
        meta: {
          title: 'title_signup',
          routeBack: Page.Register
        }
      },
      {
        path: '/forgot-password',
        name: Page.ForgotPassword,
        component: ForgotPassword,
        meta: {
          title: 'title_forgot_password',
          routeBack: Page.Login
        }
      },
      {
        path: '/forgot-password/sent',
        name: Page.ForgotPasswordSent,
        component: ForgotPasswordSent,
        meta: {
          title: 'title_forgot_password',
          routeBack: Page.Login
        }
      }
    ]
  },
  {
    path: '/graph/simulation',
    name: Page.GraphSimulation,
    component: GraphSimulation,
    meta: {title: 'title_graph_simulation'}
  },
  {
    path: '/graph/cash-flow',
    name: Page.GraphCashFlow,
    component: GraphCashFlow,
    meta: {title: 'title_cash_flow'}
  },
  {
    path: '',
    component: BlankParentNode,
    children: [
      {
        path: '/notification',
        name: Page.Notification,
        component: Notification,
        meta: {title: 'title_notification'}
      },
      {
        path: '/user',
        name: Page.UserMyMenu,
        component: UserMyMenu,
        meta: {
          title: 'title_my_menu',
          isStretchBody: true,
        },
      },
      {
        path: '/user/deactivation',
        name: Page.UserDeactivation,
        component: UserDeactivation,
        meta: {
          title: 'title_user_deactivation',
          isStretchBody: true,
        },
      },
      {
        path: '/user/change-info',
        name: Page.UserChangeInfo,
        component: UserChangeInfo,
        meta: {title: 'title_user_info'}
      },
      {
        path: '/change-email',
        name: Page.ChangeEmail,
        component: ChangeEmail,
        meta: {title: 'title_change_email'}
      },
      {
        path: '/change-email/email',
        name: Page.ChangeEmailEmail,
        component: ChangeEmailEmail,
        meta: {title: 'title_change_email'}
      },
      {
        path: '/change-email/otp',
        name: Page.ChangeEmailOtp,
        component: ChangeEmailOtp,
        meta: {title: 'title_change_email'}
      },
      {
        path: '/change-email/success',
        name: Page.ChangeEmailSuccess,
        component: ChangeEmailSuccess,
        meta: {title: 'title_change_email'}
      },
      {
        path: '/change-password',
        name: Page.ChangePassword,
        component: ChangePassword,
        meta: {title: 'title_change_password'}
      },
      {
        path: '/change-password/new',
        name: Page.ChangePasswordNew,
        component: ChangePasswordNew,
        meta: {title: 'title_change_password'}
      },
      {
        path: '/change-password/success',
        name: Page.ChangePasswordSuccess,
        component: ChangePasswordSuccess,
        meta: {title: 'title_change_password'}
      },
      {
        path: '/user/config/security',
        name: Page.ConfigSecurity,
        component: ConfigSecurity,
        meta: {title: 'title_config_security'}
      },
      {
        path: '/user/config/font-size',
        name: Page.ConfigFontSize,
        component: ConfigFontSize,
        meta: {title: 'title_config_font'}
      },
      {
        path: '/user/config/push-notification',
        name: Page.ConfigPushNotification,
        component: ConfigPushNotification,
        meta: {title: 'title_config_push_notification'}
      },
      {
        path: '/tax',
        name: Page.TaxSession,
        component: TaxSession,
        meta: {title: 'title_tax_list'}
      },
      {
        path: '/tax/email-notice',
        name: Page.TaxEmailProvideNotice,
        component: TaxEmailProvideNotice,
        meta: {title: 'title_tax_list'}
      },
      {
        path: '/tax/:id/trust',
        name: Page.TaxTrustingConfirmation,
        component: TaxTrustingConfirmation,
        meta: {title: 'title_tax_list'}
      },
      {
        path: '/tax/:id/trust-only',
        name: Page.TaxTrustingConfirmationForce,
        component: TaxTrustingConfirmationForce,
        meta: {title: 'title_tax_list'}
      },
      {
        path: '/tax/:id/document',
        name: Page.TaxDocumentChecking,
        component: TaxDocumentChecking,
        meta: {title: 'title_tax_list'}
      },
      {
        path: '/tax/:id/progress',
        name: Page.TaxProgress,
        component: TaxProgress,
        meta: {title: 'title_tax_list'}
      },
    ]
  }
]
