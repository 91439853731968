<template>
  <div class="wrapper-cash-flow">
    <!-- MONTH SELECT -->
    <div
      v-if="dataMonth"
      class="month_select"
    >
      <select
        v-model="selectMonth"
        @change="updateState"
      >
        <option
          v-for="(item, idx) in chartOptions.labels"
          :key="idx"
          :value="item"
        >
          {{ item + '月' }}
        </option>
      </select>
      <span> Select month for more information </span>
    </div>

    <!-- MAIN CHART CONTENT -->
    <div
      class="chart_content cash_flow"
    >
      <apexchart
        ref="updateRealtime"
        :options="chartOptions"
        :series="series"
      />
    </div>

    <!-- LEGEND BLOCK -->
    <div
      class="chart_legend chart_legend_left"
    >
      <div
        v-for="(item, idx) in series"
        :key="item.name"
        class="chart_legend_item"
      >
        <svg
          :width="item.type === 'line' ? dataLegends.markers.width + 2 : dataLegends.markers.width"
          :height="dataLegends.markers.height"
        >
          <template v-if="item.type === 'line'">
            <rect
              :width="dataLegends.markers.width + 4"
              :height="2"
              transform="translate(0 4)"
            />
            <circle
              cx="3"
              cy="3"
              r="3"
              transform="translate(3, 2)"
            />
          </template>
          <rect
            v-else
            :width="dataLegends.markers.width"
            :height="dataLegends.markers.height"
            :fill="chartOptions.colors[idx]"
          />
          <!--          <polyline-->
          <!--            v-else-->
          <!--            :points="`0,0 ${dataLegends.markers.width - 1},1 ${dataLegends.markers.width - 1},${dataLegends.markers.width - 1} 1,${dataLegends.markers.width - 1} 0,0`"-->
          <!--            :style="{'stroke':chartOptions.colors[idx],'stroke-width': '3.5', 'stroke-dasharray': chartOptions.stroke.dashArray[idx], 'fill':chartOptions.colors[idx]}"-->
          <!--          />-->
        </svg>
        <span>{{ item.name }}</span>
      </div>
    </div>

    <!-- DATA BLOCK -->
    <div class="chart_info">
      <div
        v-if="dataCharIndex !== null"
        class="chart_data"
      >
        <span
          v-for="item in series"
          :key="item.name"
          class="data_item_info"
        >
          {{ item.name + ': ' + (item.data[dataCharIndex] * 10).toLocaleString() + ' 万' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'

export default {
  name: 'GraphCashFlow',
  mixins: [WebviewMixin],
  props: {
    dataLabels: {
      type: Array,
      default: () => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      }
    },
    dataSeries: {
      type: Array,
      default: () => {
        return [
          {
            name: '収入',
            type: 'bar',
            data: [383, 432, 445, 517, 474, 625, 581, 698, 452, 618, 541, 556]
          },
          {
            name: '支出',
            type: 'bar',
            data: [-443, -426, -603, -683, -583, -603, -723, -583, -663, -503, -463, -343]
          },
          {
            name: '月合計',
            type: 'line',
            data: [243, 232, 244, 217, 273, 229, 286, 297, 212, 119, 148, 136]
          }
        ]
      }
    },
    dataColors: {
      type: Array,
      default: () => {
        return ['#356391', '#F56B4E', '#000000']
      }
    },
    dataYearOld: { // this props is REQUIRED for `simulation` chart
      type: Number,
      default: null
    },
    dataLegends: {
      type: Object,
      default: () => {
        return {
          markers: {
            width: 10,
            height: 10
          },
          stroke: {
            width: 1.5,
            colors: ['#fff', '#fff', '#000'],
            curve: 'straight',
            dashArray: [0, 0, 0]
          }
        }
      }
    },
    dataStates: {
      type: Object,
      default: () => {
        return {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            allowMultipleDataPointsSelection: true,
            filter: {
              type: 'none'
            }
          }
        }
      }
    },
    dataMonth: { // this props is REQUIRED for `cash_flow` chart
      type: Number,
      default: 10
    },
    isStacked: {
      type: Boolean,
      default: true
    },
    showAnnotation: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      allPathX: [],
      selectMonth: this.$props.dataMonth,
      chartOptions: {
        colors: ['#FD562166', '#0AA9F4', '#D35BDD'], // add/edit chart color, can custom this
        chart: {
          id: 'trusty_chart_exp',
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          stacked: this.$props.isStacked,
          stackType: '50%'
        },
        labels: this.$props.dataLabels,
        states: this.$props.dataStates,
        plotOptions: {
          bar: {
            columnWidth: '75%'
          }
        },
        tooltip: {
          enabled: false,
          shared: true
        },
        legend: {
          show: false
        },
        stroke: {
          width: 0,
          curve: 'straight',
          dashArray: [0, 0, 2.5]
        },
        markers: {
          strokeOpacity: 0.1,
          strokeWidth: 5,
          size: [0, 0, 2] // width of marker
        },
        xaxis: {
          type: 'number',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        grid: {
          show: false
        },
        annotations: {
          position: 'back'
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              yaxis: {
                show: false
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: '12px'
                  }
                }
              },
              stroke: this.$props.dataLegends.stroke,
              markers: {
                strokeOpacity: 0.1,
                strokeWidth: 2,
                size: [0, 0, 1.5] // width of marker
              }
            }
          },
          {
            breakpoint: 992,
            options: {
              yaxis: {
                show: false
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: '14px'
                  }
                }
              },
              stroke: this.$props.dataLegends.stroke,
              markers: {
                strokeOpacity: 0.1,
                strokeWidth: 3,
                size: [0, 0, 4] // width of marker
              }
            }
          }]
      },
      series: this.$props.dataSeries
    }
  },

  computed: {
    dataCharIndex () {
      return this.chartOptions.labels.indexOf(this.selectMonth)
    }
  },

  created () {
    this.chartOptions.stroke.dashArray = []
    this.chartOptions.colors = this.dataColors

    // if the flag is on, other dataPointSelection callback will do nothing
    let isUpdating = false

    this.chartOptions.chart.events = {
      click: (event, chartContext, config) => {
        // clickPosition = real click pos  -  ((width between 2 bars) / 2)  +  (2 * borders)
        let clickPosition = (event.clientX ? event.clientX : event.changedTouches[0]?.clientX) - (this.allPathX[1] - this.allPathX[0]) / 2 + this.dataLegends.stroke.width * 2

        let closet = this.allPathX.reduce((a, b) => {
          return Math.abs(b - clickPosition) < Math.abs(a - clickPosition) ? b : a
        })
        let dataPointIndex = this.allPathX.indexOf(closet)

        if (!isUpdating) {
          // turn on the flag to disable other dataPointSelection callback
          isUpdating = true

          // turn off all active selection, last active selection included
          config.globals.selectedDataPoints.forEach((points, seriesIndex) => {
            points.forEach((dataPointIndex) => {
              this.$refs.updateRealtime.toggleDataPointSelection(seriesIndex, dataPointIndex)
            })
          })

          // turn on selection for select index points
          this.series.forEach((series, seriesIndex) => {
            // if the column is already active, do nothing
            if (config.globals.selectedDataPoints[seriesIndex] && ~config.globals.selectedDataPoints[seriesIndex].indexOf(dataPointIndex)) {
              return
            }
            this.$refs.updateRealtime.toggleDataPointSelection(seriesIndex, dataPointIndex)
          })

          // Update data
          this.selectMonth = this.chartOptions.labels[dataPointIndex]

          isUpdating = false
        }
      },
      dataPointSelection: (event, chartContext, config) => {
        if (!isUpdating) {
          // turn on the flag to disable other dataPointSelection callback
          isUpdating = true

          // turn off all active selection, last active selection included
          config.selectedDataPoints.forEach((points, seriesIndex) => {
            points.forEach((dataPointIndex) => {
              this.$refs.updateRealtime.toggleDataPointSelection(seriesIndex, dataPointIndex)
            })
          })

          // turn on selection for select index points
          this.series.forEach((series, seriesIndex) => {
            // if the column is already active, do nothing
            if (config.selectedDataPoints[seriesIndex] && ~config.selectedDataPoints[seriesIndex].indexOf(config.dataPointIndex)) {
              return
            }
            this.$refs.updateRealtime.toggleDataPointSelection(seriesIndex, config.dataPointIndex)
          })

          // Update data
          this.selectMonth = this.chartOptions.labels[config.dataPointIndex]

          isUpdating = false
        }
      }
    }
  },

  mounted () {
    let pathSeries = document.querySelector('.apexcharts-inner .apexcharts-series')
    let paths = pathSeries.querySelectorAll('path')

    paths.forEach(item => {
      this.allPathX.push(document.querySelector(`#${item.id}`).getBoundingClientRect().x)
    })

    this.updateState()
  },

  methods: {
    updateState () {
      // turn ON all current active `dataPoint`
      this.$refs.updateRealtime.toggleDataPointSelection(0, this.dataCharIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/page/_cash-flow.scss";
</style>
