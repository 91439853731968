<template>
  <div class="forgot-password">
    <div class="page-body">
      <div class="forgot-password__icon">
        <img
          src="/images/icons/icon-feather-mail.svg"
          alt="Email icon"
          width="31px"
        >
      </div>
      <div
        class="forgot-password__text"
        v-html="nl2br($t('desc_please_enter_registered_email'))"
      />
      <div class="forgot-password__input">
        <div class="forgot-password__input__email">
          <label>
            {{ $t('common_email_address') }}
          </label>
          <input
            v-model="form.email"
            type="email"
            name="email"
            required
            :class="{'is-invalid': form.errors && form.errors.email}"
          >
          <div class="invalid-feedback">
            {{ form.errors && form.errors.email && form.errors.email[0] }}
          </div>
        </div>
        <div class="forgot-password__input__birthday">
          <label>
            {{ $t('common_birthday') }}
          </label>
          <div
            class="relative"
            :class="{'is-invalid': form.errors && form.errors.birthday}"
          >
            <input
              ref="birthdayInputEl"
              v-model="form.birthday"
              type="text"
              placeholder="1990/1/1"
              :class="{'is-invalid': form.errors && form.errors.birthday}"
              @focus.prevent="showBirthdaySelectBox"
            >
            <img
              src="/images/icons/icon-arrow-right-gold.svg"
              width="9px"
            >
          </div>
          <div class="invalid-feedback">
            {{ form.errors && form.errors.birthday && form.errors.birthday[0] }}
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="forgot-password__submit">
        <button
          class="btn btn-primary"
          :disabled="!canSubmit"
          @click="submit"
        >
          {{ $t('common_reset_password') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {addEventListener, triggerMobileAction} from '@/utils/webview.util'
import {SELECT_BIRTHDAY} from '@/enums/mobile-event.enum'
import {KEYBOARD, SELECTED_BIRTHDAY} from '@/enums/web-event.enum'
import {AuthService} from '@/services/auth.service'
import {RequestError} from '@/errors/request.error'
import {validateEmail} from '@/utils/validate.util'
import {nl2br} from '@/utils/string.util'

export default {
  name: 'ForgotPassword',
  mixins: [WebviewMixin],
  data () {
    return {
      webview_scrollToInputOnFocused: true,
      form: {
        email: null,
        birthday: null,
        errors: {}
      }
    }
  },
  computed: {
    canSubmit () {
      return this.form.email && this.form.birthday
    }
  },
  methods: {
    nl2br,
    registerEventListeners () {
      addEventListener(SELECTED_BIRTHDAY, this.onBirthdaySelected)
      addEventListener(KEYBOARD, this.onKeyboardUpdated)
      this.notifyMobilePageReady()
    },
    onBirthdaySelected (e) {
      if (e && e.detail && e.detail.birthday) {
        this.form.birthday = e.detail.birthday
      }
    },
    validate () {
      this.form.errors = {}
      let emailError = validateEmail(this.form.email)
      if (emailError) {
        this.form.errors.email = [emailError]
      }
      return !Object.keys(this.form.errors).length
    },
    async submit () {
      if (!this.validate()) {
        return
      }

      try {
        await AuthService.sendForgotPasswordEmail(this.form)
      } catch (e) {
        if (e instanceof RequestError) {
          this.form.errors = e.details
          if (!Object.keys(e.details).length) {
            this.form.errors = {
              email: [e.message]
            }
            return
          }
        }
        throw e
      }

      this.$store.commit('user/setTempNewEmail', this.form.email)

      this.$router.push({name: this.Page.ForgotPasswordSent})
    },
    showBirthdaySelectBox () {
      this.$refs.birthdayInputEl.blur()
      triggerMobileAction(SELECT_BIRTHDAY, {})
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
.forgot-password {
  > *:not(.page-body):not(.page-footer) {
    display: none;
  }

  &__icon {
    padding-top: 70px;
    margin-bottom: 43px;
    text-align: center;
    @media (max-width: 360px){
      padding-top: 30px;
    }
    @media (max-width: 320px){
      padding-top: 15px;
    }
  }
  &__text {
    margin-bottom: 97px;
    text-align: center;
    font-size: 5.33vw;
    line-height: 30px;
    @media (max-width: 320px){
      margin-bottom: 50px;
    }
  }
  &__input {
    margin-bottom: 50px;

    input {
      font-size: 4.27vw;
      padding-left: 7px;
      padding-right: 7px;
    }

    label {
      font-size: 4.27vw;
    }

    &__email {
      margin-bottom: 20px;
    }

    &__birthday {
      &__note {
        font-size: 12px;
      }
      img {
        position: absolute;
        right: 8px;
        top: 10px;
        transform: rotate(
            90deg
        );
      }
    }
  }
  &__submit {
    padding-bottom: 40px;
    button {
      background-image: url("/images/icons/icon-feather-mail-smol.svg");
      background-repeat: no-repeat;
      background-size: 4vw;
      background-position: calc(25% - 4vw);
      font-size: 3.733vw;
      padding: 13px 0 14px;
      &:disabled {
        background-image: url("/images/icons/icon-feather-mail-smol-black.svg");
      }
    }
  }
}
</style>
