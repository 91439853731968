<template>
  <div />
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'

export default {
  name: 'ConfigFontSize',
  mixins: [WebviewMixin]
}
</script>

<style
  scoped
  lang="scss"
>
</style>
