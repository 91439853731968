// Config Request Interceptor
import store from '@/store'
import {triggerMobileAction} from '@/utils/webview.util'
import {PAGE_LOADING} from '@/enums/mobile-event.enum'
// Config Axios
export const axiosConfig = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 30000 // request timeout
}

// Config Request Interceptor
export const axiosInterceptorRequestConfig = (config) => {
  if (store.state.auth.token && !config.headers['Authorization']) {
    config.headers['Authorization'] = `Bearer ${store.state.auth.token}`
  }
  if (!config.noLoading) {
    store.commit('setAjaxLoading', true)
    triggerMobileAction(PAGE_LOADING, {toggle: true})
  }
  return config
}

// Config Request Error Interceptor
export const axiosInterceptorRequestError = (error) => {
  store.commit('setAjaxLoading', false)

  triggerMobileAction(PAGE_LOADING, {toggle: false})

  return Promise.reject(error)
}
