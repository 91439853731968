export const PAGE_READY = 'PAGE_READY'
export const PAGE_REDIRECT = 'PAGE_REDIRECT'
export const PAGE_LOADING = 'PAGE_LOADING'
export const PAGE_REDIRECT_EXTERNAL = 'PAGE_REDIRECT_EXTERNAL'

export const LOGIN_WITH_APPLE = 'LOGIN_WITH_APPLE'
export const AUTHEN_SUCCESS = 'AUTHEN_SUCCESS'
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'
export const TOKEN_NEW = 'TOKEN_NEW'

export const SELECT_BIRTHDAY = 'SELECT_BIRTHDAY'

export const DATA_PROPERTY = 'DATA_PROPERTY'
export const SELECT_PROPERTY = 'SELECT_PROPERTY'
export const SELECT_YEAR_MONTH = 'SELECT_YEAR_MONTH'

export const CONFIRM_LOGOUT = 'CONFIRM_LOGOUT'
export const CONFIRM_DEACTIVATE = 'CONFIRM_DEACTIVATE'
export const CONFIRM_CONTACT_BROWSER = 'CONFIRM_CONTACT_BROWSER'
export const CONFIRM_CHANGE_EMAIL = 'CONFIRM_CHANGE_EMAIL'
export const CONFIRM_TAX_SESSION = 'CONFIRM_TAX_SESSION'

export const OPEN_NOTIFICATION_LIST = 'OPEN_NOTIFICATION_LIST'
export const OPEN_NOTIFICATION = 'OPEN_NOTIFICATION'

export const UPDATE_CONFIG = 'UPDATE_CONFIG'
export const UPDATE_DEVICE_CONFIG = 'UPDATE_DEVICE_CONFIG'

export const OPEN_BROWSER = 'OPEN_BROWSER'
export const OPEN_LICENSES = 'OPEN_LICENSES'
export const OPEN_WEBVIEW = 'OPEN_WEBVIEW'
export const LOAD_INTERNAL_LINK = 'LOAD_INTERNAL_LINK'

export const WEBVIEW_MODEL_SHOW = 'WEBVIEW_MODEL_SHOW'
export const WEBVIEW_MODEL_HIDE = 'WEBVIEW_MODEL_HIDE'

export const ERROR = 'ERROR'

export const SHOW_TOAST = 'SHOW_TOAST'

export const LOG = 'LOG'

export const SHOW_ERROR_RETRY = 'SHOW_ERROR_RETRY'
export const SHOW_ERROR = 'SHOW_ERROR'
