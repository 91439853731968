// Config Response Interceptor
import store from '@/store'
import _get from 'lodash-es/get'
import {RequestError} from '@/errors/request.error'
import {triggerMobileAction} from '@/utils/webview.util'
import {PAGE_LOADING, SHOW_ERROR, SHOW_ERROR_RETRY, TOKEN_EXPIRED} from '@/enums/mobile-event.enum'
import {HTTP_UNAUTHORIZED} from '@/enums/http-code.enum'
import i18n from '@/plugins/i18n'
import router from '@/router'
import * as Page from '@/enums/page-name.enum'
import {includes} from 'lodash-es'

export const axiosInterceptorResponseConfig = (response) => {
  store.commit('setAjaxLoading', false)

  if (!response.config.noLoading) {
    triggerMobileAction(PAGE_LOADING, {toggle: false})
  }

  return response
}

// Config Response Error Interceptor
export const axiosInterceptorResponseError = (error) => {
  store.commit('setAjaxLoading', false)

  triggerMobileAction(PAGE_LOADING, {toggle: false})

  if (error) {
    // eslint-disable-next-line no-unused-vars
    const status = _get(error, 'response.status')
    let message = _get(error, 'response.data.meta.message')
    let details = _get(error, 'response.data.data.trace')

    const network = error.toJSON() || error.response

    if (network.message === 'Network Error') {
      return triggerEvent(network, status, i18n.t('has_error_network'))
    }

    let isFormSubmitPage = includes([
      Page.Register,
      Page.RegisterOtp,
      Page.RegisterPassword,
      Page.RegisterCustomerCode,

      Page.Login,
      Page.LoginCustomerCode,

      Page.ChangeEmail,
      Page.ChangeEmailEmail,
      Page.ChangeEmailOtp,

      Page.ChangePassword,
      Page.ChangePasswordNew,

      Page.ForgotPassword,

      Page.UserChangeInfo
    ], router.app.$route.name)

    let loggedInPage = includes([
      Page.ChangeEmail,
      Page.ChangeEmailEmail,
      Page.ChangeEmailOtp,

      Page.ChangePassword,
      Page.ChangePasswordNew,

      Page.UserChangeInfo
    ], router.app.$route.name)

    if (isFormSubmitPage) {
      if (status === HTTP_UNAUTHORIZED && loggedInPage) {
        return triggerMobileAction(TOKEN_EXPIRED)
      }
      if (network.config.method !== 'get') {
        return Promise.reject(new RequestError({message, status, details}))
      }
      return triggerEvent(network, status, message)
    } else {
      return triggerEvent(network, status, i18n.t('has_error'))
    }
  } else {
    // return empty object for aborted request
    return Promise.resolve({})
  }
}

export const triggerEvent = (network, status, errorMessage) => {
  if (network.config.method === 'get') {
    if (status === HTTP_UNAUTHORIZED) {
      return triggerMobileAction(TOKEN_EXPIRED)
    }
    return triggerMobileAction(SHOW_ERROR_RETRY, {message: errorMessage})
  }
  return triggerMobileAction(SHOW_ERROR, {message: errorMessage})
}
