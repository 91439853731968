<template>
  <div class="login">
    <div class="page-body">
      <div class="login__input">
        <div
          class="login__input__email form-control"
          :class="{'is-invalid': form.errors.email}"
        >
          <input
            v-model="form.email"
            type="email"
            name="email"
            :placeholder="$t('common_email_address')"
            :class="{'is-invalid': form.errors && form.errors.email}"
          >
          <div class="invalid-feedback">
            {{ form.errors && form.errors.email && form.errors.email[0] }}
          </div>
        </div>
        <div class="login__input__password form-control">
          <div
            class="relative"
            :class="{'is-invalid': form.errors && form.errors.password}"
          >
            <input
              ref="passwordInputEl"
              v-model="form.password"
              :type="form.options.showPassword ? 'text' : 'password'"
              :placeholder="$t('common_password')"
              :class="{'is-invalid': form.errors && form.errors.password}"
            >
            <template>
              <img
                v-if="form.options.showPassword"
                src="/images/icons/icon-feather-eye.svg"
                width="14px"
                alt="Show password"
                @click="form.options.showPassword = false, $refs.passwordInputEl.focus()"
              >
              <img
                v-else
                src="/images/icons/icon-feather-eye-off.svg"
                width="14px"
                alt="Hide password"
                @click="form.options.showPassword = true, $refs.passwordInputEl.focus()"
              >
            </template>
          </div>
          <div class="invalid-feedback">
            {{ form.errors && form.errors.password && form.errors.password[0] }}
          </div>
        </div>
        <div class="login__input__forgot-password">
          <router-link
            v-slot="{ navigate }"
            :to="{name: Page.ForgotPassword}"
            custom
          >
            <span
              class="a-like"
              @click="navigate"
            >
              {{ $t('common_click_if_forgot_password') }}
            </span>
          </router-link>
        </div>

        <button
          :disabled="!canSubmit"
          class="btn btn-primary"
          @click.prevent="submit"
        >
          {{ $t('common_login') }}
        </button>
      </div>

      <div class="login__sns">
        <div class="login__sns__text">
          {{ $t('common_login_with_sns') }}
        </div>
        <button
          class="btn btn__face"
          @click="firebase_loginWithFacebook"
        >
          <img
            src="/images/icons/icon-login-facebook.svg"
            alt="icon-login-facebook"
          >
          <span>{{ $t('common_login_with_facebook') }}</span>
        </button>
        <button
          class="btn btn__google"
          @click="firebase_loginWithGoogle"
        >
          <img
            src="/images/icons/icon-login-google.svg"
            alt="icon-login-google"
          >
          <span>{{ $t('common_login_with_google') }}</span>
        </button>
        <button
          class="btn btn__apple"
          @click="appleAuth_signInWithApple"
        >
          <img
            src="/images/icons/icon-login-apple.svg"
            alt="icon-login-apple"
          >
          <span>{{ $t('common_login_with_apple') }}</span>
        </button>
      </div>
    </div>
    <div class="page-footer">
      <div class="login__text">
        <router-link
          v-slot="{ navigate }"
          :to="{name: Page.Register}"
          custom
        >
          <span
            class="btn__register"
            @click="navigate"
          >
            {{ $t('common_register_from_here') }}
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import FirebaseAuthMixin from '@/mixins/firebase-auth.mixin'
import AppleAuthMixin from '@/mixins/apple-auth.mixin'
import AuthMixin from '@/mixins/auth.mixin'
import {validateEmail} from '@/utils/validate.util'
import {AuthService} from '@/services/auth.service'
import {RequestError} from '@/errors/request.error'
import {mapGetters} from 'vuex'
import ErrorHandler from '@/errors/handler'

export default {
  name: 'Login',
  mixins: [
    WebviewMixin,
    FirebaseAuthMixin,
    AuthMixin,
    AppleAuthMixin,
  ],
  data () {
    return {
      form: {
        email: null,
        password: null,
        options: {
          showPassword: false
        },
        errors: {}
      },
      userToken: null,
    }
  },
  computed: {
    canSubmit () {
      return this.form.email && this.form.password && !this.loading
    },
    ...mapGetters({
      loading: 'loadingAny'
    })
  },
  methods: {
    async init () {
      await this.$store.dispatch('toggleLoading', true)
      await this.appleAuth_init()
      await this.firebase_init()
      await this.$store.dispatch('toggleLoading', false)

      this.notifyMobilePageReady()

      if (this.$route.query.error) {
        ErrorHandler.triggerError(`${this.$route.query.error}`)
        const newState = this.$router.resolve({
          name: this.Page.Login,
          query: {
            platform: this.$route.query.platform
          }
        }).href
        window.history.pushState({}, null, newState)
      }
    },
    validate () {
      this.form.errors = {}

      let emailError = validateEmail(this.form.email)
      if (emailError) {
        this.form.errors.email = [emailError]
      }

      return !Object.keys(this.form.errors).length
    },
    async submit () {
      if (!this.validate()) {
        return
      }

      let loginResult
      try {
        loginResult = await AuthService.login(this.form)
      } catch (e) {
        if (e instanceof RequestError) {
          this.form.errors = e.details
          if (!Object.keys(e.details).length) {
            this.form.errors = {
              email: [e.message]
            }
            return
          }
        }
        throw e
      }

      this.onAuthenticated(loginResult.data, true)
    },
    appleAuth_onAuthenticated (data) {
      this.onAuthenticated(data)
    },
    firebase_onAuthenticated (data) {
      this.onAuthenticated(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  font-size: 14px;
  > *:not(.page-body):not(.page-footer) {
    display: none;
  }

  .page-body {
    padding-top: 65px;
    @media (max-width: 320px){
      padding-top: 15px;
    }
  }
  .page-footer {
    padding-bottom: 24px;
    @media (max-width: 320px){
      padding-bottom: 15px;
    }
  }

  &__input {
    margin-bottom: 30px;

    &__email {
      margin-bottom: 30px;
      &.is-invalid {
        margin-bottom: 20px;
      }

      input {
        padding: 10px 7px;
      }
    }
    &__password {
      margin-bottom: 10px;
      input {
        padding-left: 7px;
        padding-right: 25px;
      }

      img {
        position: absolute;
        right: 7px;
        top: 9px;
      }
    }
    &__forgot-password {
      display: block;
      text-align: right;
      margin-bottom: 19px;
      .a-like {
        font-size: 12px;
      }
    }

    > button {
      padding: 12px 0;
      font-size: 3.733vw;
    }

    label {
      display: block;
    }
    input{
      width: 100%;
      font-size: 4.27vw;
      &::placeholder {
        color: $text-gray;
      }
    }
    .btn__login {
      width: 100%;
      border: none;
      background-color: $btn-orange;
      color: $color-default-lightest;
      padding: 13px 15px;
      &:focus {
        outline: none;
      }
      &:disabled {
        pointer-events: none;
        background-color: $bg-gray;
        color: $color-default-darkest;
      }
    }
  }

  &__sns {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__text {
      margin-bottom: 19px;
      font-size: 12px;
      color: $text-darken-gray-1;
    }
    .btn {
      background-color: #fff;
      border: 1px solid $border-gray;
      margin-bottom: 10px;
      padding: 10px 0 10px 20%;
      display: flex;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }

      img {
        width: 20px;
      }
      span {
        width: calc(100% - 86px);
        font-size: 3.733vw;
      }
    }
  }

  &__text {
    text-align: center;
    .btn__register {
      color: $color-default-darkest;
      text-decoration: underline;
      font-size: 3.733vw;
    }
  }
}
</style>
