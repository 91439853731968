<template>
  <div class="tax-trusting-confirmation-force">
    <div class="page-body">
      <div class="tax-trusting-confirmation-force__text">
        <p
          style="line-height: 22px"
          v-html="nl2br($t('inform_tax_1'))"
        />
        <p>{{ nl2br($t('inform_tax_2')) }}</p>
      </div>
    </div>
    <div class="page-footer">
      <div class="tax-trusting-confirmation-force__submit">
        <button
          class="btn btn-primary radius-5"
          :disabled="!canSubmit"
          @click="registerSession"
          v-text="nl2br($t('confirmation_report_submit'))"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {nl2br} from '@/utils/string.util'
import WebviewMixin from '@/mixins/webview.mixin'
import {mapGetters} from 'vuex'
import {TaxService} from '@/services/tax.service'

export default {
  name: 'TaxTrustingConfirmationForce',
  mixins: [WebviewMixin],
  computed: {
    canSubmit () {
      return this.hasToken && !this.loading
    },
    ...mapGetters({
      hasToken: 'auth/hasToken',
      loading: 'loadingAny'
    })
  },
  methods: {
    nl2br,
    async registerSession () {
      try {
        const res = await TaxService.registerSession(this.$route.params.id, true)
        // if register success
        if (Object.keys(res).length > 0) {
          await this.$router.replace({
            name: this.Page.TaxDocumentChecking,
            params: {...this.$route.params},
            query: {...this.$route.query}
          }).catch(err => err)
        }
      } catch (e) {
        throw e
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tax-trusting-confirmation-force {
  padding: 30px 20px 108px;

  &__text {
    line-height: 19px;

    p:first-child {
      margin-bottom: 40px;
    }
  }
}
</style>
