let preloadList = [
  '/images/icons/icon-arrow-left.svg',
  '/images/icons/icon-arrow-right-gold.svg',
  '/images/icons/icon-arrow-right-gray.svg',
  '/images/icons/icon-arrow-top.svg',
  '/images/icons/icon-bell-black-outline.svg',
  '/images/icons/icon-feather-eye.svg',
  '/images/icons/icon-feather-eye-off.svg',
  '/images/icons/icon-feather-lock.svg',
  '/images/icons/icon-feather-mail.svg',
  '/images/icons/icon-feather-mail-smol.svg',
  '/images/icons/icon-feather-mail-smol-black.svg',
  '/images/icons/icon-feather-unlock.svg',
  '/images/icons/icon-ionic-ios-information-circle.svg',
  '/images/icons/icon-login-apple.svg',
  '/images/icons/icon-login-facebook.svg',
  '/images/icons/icon-login-google.svg',
  '/images/icons/icon-notebook.svg',
  '/images/icons/icon-person.svg',
  '/images/icons/icon-simple-minutemailer.svg',
]

export function preloadImages () {
  preloadList.forEach((src) => {
    const img = new Image()
    img.src = src
  })
}
