<template>
  <div class="home container">
  </div>
</template>

<script>
import * as Page from '@/enums/page-name.enum'

export default {
  name: 'Home',
  data () {
    return {
      Page
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  a {
    display: block;
    width: 100%;
  }
}
</style>
