<template>
  <div
    class="container screen-container"
    :lang="$i18n.locale"
    :class="{
      'with-header': metaStack.hasHeader,
      'with-footer': metaStack.hasFooter,
      ios: platform === EPlatform.IOS,
      android: platform === EPlatform.ANDROID
    }"
  >
    <div class="screen-wrapper">
      <router-view
        v-if="metaStack.hasHeader"
        key="Header"
        name="header"
        class="screen-header"
      />
      <div
        key="Body"
        class="screen-body"
        :class="{
          'with-header': metaStack.hasHeader,
          'with-footer': metaStack.hasFooter,
          'screen-body--stretch': metaStack.isStretchBody
        }"
      >
        <router-view />
      </div>
      <router-view
        v-if="metaStack.hasFooter"
        key="Footer"
        name="footer"
        class="screen-footer"
      />
    </div>
    <div :style="keyboardSpaceStyle" />
  </div>
</template>

<script>
import RouterMetaStack from '@/mixins/router-meta-stack.mxin'
import {mapGetters} from 'vuex'
import * as EPlatform from '@/enums/platform'

export default {
  name: 'App',
  mixins: [
    RouterMetaStack
  ],
  data () {
    return {
      EPlatform
    }
  },
  computed: {
    ...mapGetters({
      platform: 'config/platform',
      keyboardHeight: 'keyboardHeight'
    }),
    keyboardSpaceStyle () {
      const style = {
        height: `${this.keyboardHeight}px`
      }
      if (this.metaStack.hasFooter) {
        style.backgroundColor = 'var(--color-background-footer)'
      }
      return style
    },
  },
  watch: {
    // we have to do this because this app isn't server-side
    // welcome any better ideal
    '$i18n.locale' () {
      this.changePageTitle()
    },
    '$route.name' () {
      this.changePageTitle()
    },
    '$route.query' () {
      this.changePlatform()
    }
  },
  created () {
    this.changePlatform()
    this.changePageTitle()
  },
  methods: {
    changePageTitle () {
      document.title = this.$route.meta.title ? this.$t(this.$route.meta.title) : 'TRUSTY APP'
    },
    changePlatform () {
      if (this.$route.query.platform) {
        this.$store.commit('config/setPlatform', Number(this.$route.query.platform))
      }
    }
  }
}
</script>
