const state = () => ({
  // To store temporary new email while changing email
  tempNewEmail: null,
})

const getters = {
  tempNewEmail: state => state.tempNewEmail
}

const mutations = {
  setTempNewEmail (state, email) {
    state.tempNewEmail = email
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
