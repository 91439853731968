<template>
  <div class="register">
    <div class="page-body">
      <div class="register__email">
        <div class="form-control">
          <input
            v-model="form.email"
            type="email"
            name="email"
            :placeholder="$t('common_email_address')"
            :class="{'is-invalid': form.errors && form.errors.email}"
            required
          >
          <div class="invalid-feedback">
            {{ form.errors && form.errors.email && form.errors.email[0] }}
          </div>
        </div>

        <button
          class="btn btn-primary"
          :disabled="!canSubmit"
          @click="loginWithEmail"
        >
          {{ $t('common_register') }}
        </button>
      </div>

      <div class="register__sns">
        <div class="register__sns__text">
          {{ $t('common_register_with_sns') }}
        </div>
        <button
          class="btn btn__face"
          @click="firebase_loginWithFacebook"
        >
          <img
            src="/images/icons/icon-login-facebook.svg"
            alt="icon-login-facebook"
          >
          <span>{{ $t('common_login_with_facebook') }}</span>
        </button>
        <button
          class="btn btn__google"
          @click="firebase_loginWithGoogle"
        >
          <img
            src="/images/icons/icon-login-google.svg"
            alt="icon-login-google"
          >
          <span>{{ $t('common_login_with_google') }}</span>
        </button>
        <button
          class="btn btn__apple"
          @click="appleAuth_signInWithApple"
        >
          <img
            src="/images/icons/icon-login-apple.svg"
            alt="icon-login-apple"
          >
          <span>{{ $t('common_login_with_apple') }}</span>
        </button>
      </div>
    </div>
    <div class="page-footer">
      <div class="register__text">
        <router-link
          v-slot="{ navigate }"
          :to="{name: 'Login'}"
          custom
        >
          <span
            class="btn__login"
            @click="navigate"
          >
            {{ $t('common_already_had_account_login') }}
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import FirebaseAuthMixin from '@/mixins/firebase-auth.mixin'
import AuthMixin from '@/mixins/auth.mixin'
import {AuthService} from '@/services/auth.service'
import {mapGetters} from 'vuex'
import {RequestError} from '@/errors/request.error'
import {createUser} from '@/models/user.model'
import {validateEmail} from '@/utils/validate.util'
import ErrorHandler from '@/errors/handler'
import AppleAuthMixin from '@/mixins/apple-auth.mixin'

export default {
  name: 'Register',
  mixins: [
    WebviewMixin,
    FirebaseAuthMixin,
    AuthMixin,
    AppleAuthMixin,
  ],
  data () {
    return {
      form: {
        email: null,
        errors: {}
      },
    }
  },
  computed: {
    canSubmit () {
      return this.form.email && !this.loading
    },
    ...mapGetters({
      loading: 'loadingAny'
    })
  },
  methods: {
    async init () {
      await this.$store.dispatch('toggleLoading', true)
      await this.appleAuth_init()
      await this.firebase_init()
      await this.$store.dispatch('toggleLoading', false)

      this.notifyMobilePageReady()

      if (this.$route.query.error) {
        ErrorHandler.triggerError(`${this.$route.query.error}`)
        const newState = this.$router.resolve({
          name: this.Page.Register,
          query: {
            platform: this.$route.query.platform
          }
        }).href
        window.history.pushState({}, null, newState)
      }
    },
    validate () {
      this.form.errors = {}
      let emailError = validateEmail(this.form.email)
      if (emailError) {
        this.form.errors.email = [emailError]
      }
      return !Object.keys(this.form.errors).length
    },
    async loginWithEmail () {
      if (!this.validate()) {
        return
      }

      let registerResult
      try {
        registerResult = await AuthService.register(this.form)
      } catch (e) {
        if (e instanceof RequestError) {
          this.form.errors = e.details
          if (!e.details.email) {
            this.form.errors = {
              email: [e.message]
            }
            return
          }
        }
        throw e
      }

      this.$store.commit('auth/setToken', registerResult.data)
      this.$store.commit('auth/setCurrentUser', createUser(registerResult.data))

      this.$router.push({name: this.Page.RegisterOtp})
    },
    firebase_onAuthenticated (data) {
      this.onAuthenticated(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.register {
  font-size: 14px;
  > *:not(.page-body):not(.page-footer) {
    display: none;
  }

  .page-body {
    padding-top: 70px;
    @media (max-width: 320px){
      padding-top: 15px;
    }
  }
  .page-footer {
    padding-bottom: 38px;
  }

  &__email {
    width: 100%;
    margin-bottom: 59px;
    input{
      width: 100%;
      font-size: 4.27vw;
      padding: 10px 7px;
      &::placeholder {
        color: $text-gray;
      }
    }
    .form-control {
      margin-bottom: 30px;
    }
    .btn__sign-up {
      border: none;
      background: $bg-gray;
      color: $color-default-darkest;
      width: 100%;
    }
    > button {
      padding: 12px 0;
      font-size: 3.733vw;
    }
  }

  &__sns {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__text {
      margin-bottom: 19px;
      font-size: 12px;
      color: $text-darken-gray-1;
    }
    .btn {
      background-color: #fff;
      border: 1px solid $border-gray;
      margin-bottom: 10px;
      padding: 10px 0 10px 20%;
      display: flex;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }

      img {
        width: 20px;
      }
      span {
        width: calc(100% - 86px);
        font-size: 3.733vw;
      }
    }
  }

  &__text {
    text-align: center;
    .btn__login {
      color: $color-default-darkest;
      text-decoration: underline;
      font-size: 3.733vw;
    }
  }
}
</style>
