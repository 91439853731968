\<template>
  <div class="register-password">
    <div class="page-body">
      <div class="register-password__icon">
        <img
          src="/images/icons/icon-feather-unlock.svg"
          alt="Password icon"
          width="27px"
        >
      </div>
      <div class="register-password__text">
        <div class="register-password__text__title">
          {{ $t('desc_please_enter_your_password') }}
        </div>
        <div class="register-password__text__desc">
          {{ $t('common_password_rule_desc') }}
        </div>
      </div>
      <div class="register-password__input">
        <div>
          <div
            class="relative"
            :class="{'is-invalid': form.errors && form.errors.password}"
          >
            <input
              ref="passwordInputEl"
              v-model="form.password"
              :type="form.options.showPassword ? 'text' : 'password'"
              :class="{'is-invalid': form.errors && form.errors.password}"
              :placeholder="$t('common_password')"
            >
            <template>
              <img
                v-if="form.options.showPassword"
                src="/images/icons/icon-feather-eye.svg"
                width="14px"
                alt="Show password"
                @click="form.options.showPassword = false, $refs.passwordInputEl.focus()"
              >
              <img
                v-else
                src="/images/icons/icon-feather-eye-off.svg"
                width="14px"
                alt="Hide password"
                @click="form.options.showPassword = true, $refs.passwordInputEl.focus()"
              >
            </template>
          </div>
          <div
            class="invalid-feedback"
            v-html="form.errors && form.errors.password && nl2br(form.errors.password[0])"
          />
        </div>
        <div>
          <div
            class="relative"
            :class="{'is-invalid': form.errors && form.errors.passwordConfirmation}"
          >
            <input
              ref="passwordConfirmInputEl"
              v-model="form.passwordConfirmation"
              :type="form.options.showPasswordConfirmation ? 'text' : 'password'"
              :class="{'is-invalid': form.errors && form.errors.passwordConfirmation}"
              :placeholder="$t('common_password_confirm')"
            >
            <template>
              <img
                v-if="form.options.showPasswordConfirmation"
                src="/images/icons/icon-feather-eye.svg"
                width="14px"
                alt="Show password"
                @click="form.options.showPasswordConfirmation = false, $refs.passwordConfirmInputEl.focus()"
              >
              <img
                v-else
                src="/images/icons/icon-feather-eye-off.svg"
                width="14px"
                alt="Hide password"
                @click="form.options.showPasswordConfirmation = true, $refs.passwordConfirmInputEl.focus()"
              >
            </template>
          </div>
          <div
            class="invalid-feedback"
            v-html="form.errors && form.errors.passwordConfirmation && nl2br(form.errors.passwordConfirmation[0])"
          />
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="register-password__submit">
        <button
          class="btn"
          :disabled="!canSubmit"
          @click="submit"
        >
          {{ $t('common_register') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import AuthMixin from '@/mixins/auth.mixin'
import {AuthService} from '@/services/auth.service'
import {RequestError} from '@/errors/request.error'
import {HTTP_UNAUTHORIZED} from '@/enums/http-code.enum'
import {showToast} from '@/utils/webview.util'
import {validatePassword, validatePasswordConfirm} from '@/utils/validate.util'
import {nl2br} from '@/utils/string.util'

export default {
  name: 'RegisterPassword',
  mixins: [
    WebviewMixin,
    AuthMixin
  ],
  data () {
    return {
      webview_scrollToInputOnFocused: true,
      form: {
        password: null,
        passwordConfirmation: null,
        options: {
          showPassword: false,
          showPasswordConfirmation: false
        },
        errors: {}
      }
    }
  },
  computed: {
    canSubmit () {
      return this.form.password && this.form.passwordConfirmation
    }
  },
  methods: {
    nl2br,
    validate () {
      this.form.errors = {}

      let passwordError = validatePassword(this.form.password)
      if (passwordError) {
        this.form.errors.password = [passwordError]
      }

      let passwordConfirmError = validatePasswordConfirm(this.form.password, this.form.passwordConfirmation)
      if (passwordConfirmError) {
        this.form.errors.passwordConfirmation = [passwordConfirmError]
      }

      return !Object.keys(this.form.errors).length
    },
    async submit () {
      if (!this.validate()) {
        return
      }

      // send request
      let registerResult
      try {
        registerResult = await AuthService.registerPassword(this.form)
      } catch (e) {
        if (!(e instanceof RequestError)) {
          throw e
        }

        // Redirect to Login if the key is expired
        if (e.status === HTTP_UNAUTHORIZED) {
          showToast(e.message)
          this.$router.push({name: this.Page.Register})
          return
        }

        // Show form error
        this.form.errors = e.details
        throw e
      }

      this.onAuthenticated(registerResult.data)
    }
  }
}
</script>

<style scoped lang="scss">
.register-password {
  font-size: 14px;
  text-align: center;

  .page-body {
    padding-top: 38px;
    @media (max-width: 320px){
      padding-top: 15px;
    }
  }

  &__icon {
    margin-bottom: 36px;
    text-align: center;
  }

  &__text {
    margin-bottom: 48px;
    text-align: center;

    &__title {
      font-size: 5.33vw;
      margin-bottom: 9px;
      color: var(--color-text-title);
    }

    &__desc {
      color: var(--color-text-desc);
      font-weight: 300;
      font-size: 3.733vw;
    }
  }

  &__input {
    margin-bottom: 137px;
    @media (max-width: 320px){
      margin-bottom: 50px;
    }
    input {
      height: 32px;
      padding: 0 25px 0 5px;
      font-size: 3.733vw;
    }

    img {
      position: absolute;
      right: 7px;
      top: 9px;
    }

    & > div:not(:last-child) {
      margin-bottom: 40px;
    }

    .invalid-feedback {
      text-align: left;
    }
  }

  &__submit {
    margin-bottom: 40px;

    .btn {
      width: 100%;
      border: none;
      background-color: $btn-orange;
      color: $color-default-lightest;
      padding: 13px 15px;
      font-size: 3.733vw;
      &:disabled {
        pointer-events: none;
        background-color: $bg-gray;
        color: $color-default-darkest;
      }
    }
  }
}
</style>
