export default {
  btn_enter_email: 'Enter your email address',
  btn_add_friend: 'Add friend',
  common_already_had_account_login: 'Login if you already have an account',
  common_app_version: 'Version {version}',
  common_birthday: 'Date of birth',
  common_click_if_forgot_password: 'If you forget your password, click here',
  common_confirm: 'Confirm',
  common_customer_code: 'Customer code',
  common_customer_code_label_info: 'This is the code distributed to customers of Trustee Partners Co., Ltd. \n If you have any questions, please contact us. ',
  common_customer_number: 'Customer number',
  common_email_address: 'Email address',
  common_enable_device_authentication: 'Enable terminal authentication',
  common_enable_device_authentication_desc: 'You can increase the security of app login',
  common_enable_notifications: 'Enable notifications',
  common_fullname: 'Name',
  common_login: 'Login',
  common_login_configuration: 'Login authentication',
  common_login_with_sns: 'Login with external account',
  common_logout: 'Logout',
  common_notification: 'Notification',
  common_ok: 'OK',
  common_password: 'Password',
  common_new_password: 'New password',
  common_password_confirm: 'Password (confirm)',
  common_password_rule_desc: '* 8 or more single-byte alphanumeric characters',
  common_please_enter_single_byte: 'Please enter in half-width numbers',
  common_register: 'Register',
  common_register_from_here: 'Click here to register as a new member',
  common_register_with_sns: 'New registration with external account',
  common_resend_otp_after_seconds: 'You can resend the PIN code after {seconds} second | You can resend the PIN code after {seconds} seconds',
  common_resend_otp_email: 'Resend confirmation email',
  common_resend_otp_email_after_seconds: 'The confirmation email can be resent after {seconds} second | The confirmation email can be resent after {seconds} seconds',
  common_reset_password: 'Reset password',
  common_save: 'Save',
  common_login_with_facebook: 'Sign in with Facebook',
  common_login_with_google: 'Sign in with Google',
  common_login_with_apple: 'Sign in with Apple',
  common_sama: '-sama',
  common_here: 'here',
  tax_button: 'Request for final tax return for this year',
  confirmation_report_submit: 'Ask',
  confirmation_information_ready: 'Information ready',
  confirmation_report_back: 'Do not ask',
  desc_annual_tax_return: '{content} ({final_year}) Annual tax return',
  desc_completed_reset_email: 'Email address\nset up',
  desc_completed_reset_password: 'Change password\n completed',
  desc_customer_code: 'Customer code: {code}',
  desc_deactivation_title: 'Would you like to withdraw from makeA?',
  desc_deactivation: 'When you leave, your account will be deleted.\nAre you sure you want to withdraw?',
  desc_deactivation_btn: 'Proceed with the withdrawal procedure',
  desc_from_makea_office: 'From the makeA administration office',
  desc_note_new_password_in_email: 'We have emailed your new password. Please log in with that password and change your password',
  desc_please_enter_customer_code_birthday: 'Please enter\n your customer code and date of birth',
  desc_please_enter_login_password: 'Please enter\n your login password',
  desc_please_enter_new_email: 'Please enter\n email address',
  desc_please_enter_registered_email: 'Please enter your registered email address',
  desc_please_enter_your_password: 'Please register your password',
  desc_tax_return_introduce: 'If you would like support for final tax returns for the {financial_year} year ({financial_year_ja}), please tap the \'Request\' button below. \\nIf you do not need tax return support, please tap "Do not request".',
  desc_tax_return_introduce_sub_1: '*We will send you the document (data) by email at a later date.',
  desc_tax_return_introduce_sub_2_1: '*The e-mail address to receive can be set/changed from',
  desc_tax_return_introduce_sub_2_2: '', // it's empty because of language
  desc_tax_contact_header: 'Inquiries regarding “Final tax return support”',
  desc_tax_contact_note: 'Please contact our sales representative directly (recommended)',
  desc_tax_contact_line_header: 'Inquiry via official LINE',
  desc_tax_contact_line_content_1: 'Final Tax Return (LINE)',
  desc_tax_contact_line_content_2: 'Click here to add LINE friends→',
  desc_tax_contact_phone_header: 'Contact us by phone',
  desc_tax_contact_phone_time_range: '(Weekdays 9:30-18:00 excluding weekends and holidays)',
  desc_tax_contact_send_mail_notice: 'Please send the instructions to your registered email address.\nIt has been sent. Please confirm.',
  desc_tax_contact_methods_header: 'The documents you have prepared for the final tax return are\nPlease send it to us using one of the methods below.',
  desc_tax_contact_method_google_drive_header: '①Storing data to Google Drive [exclusively for {customerName}-san]',
  desc_tax_contact_method_google_drive_url: 'Open Google Drive',
  desc_tax_contact_method_email_header: '② Send data by email',
  desc_tax_contact_method_email_desc: 'Destination email address',
  desc_tax_contact_method_address_header: '③ Send by mail',
  desc_tax_contact_method_address_content: '〒150-0013\n8th floor, Ebisu SS Building, 4-3-14 Ebisu, Shibuya-ku, Tokyo\no Trusty Partners Co., Ltd.\nBusiness Management Department',
  desc_tax_email_provide_notice_1: 'We will send you a set of documents by e-mail to support your tax return.',
  desc_tax_email_provide_notice_2: 'Since you are logged in with an external account, please register the e-mail address to which you will send the documents.',
  desc_tax_email_provide_notice_3: '* Once set, the documents will be sent to the set e-mail address from the next time onwards.',
  desc_tax_email_provide_notice_4: '* You can change your email address at any time.',
  desc_tax_email_provide_notice_5: '* The email address set here is not a login email address. You can continue to log in with an external account.',
  nav_about_app: 'About the app',
  nav_back_to_login: 'Return to login',
  nav_change_email: 'Change email address',
  nav_change_password: 'Change password',
  nav_column: 'Column',
  nav_company_info: 'Operating company',
  nav_config: 'Config',
  nav_config_push_notification: 'Push notification settings',
  nav_config_security: 'Security',
  nav_contact_us: 'Contact',
  nav_deactivate: 'Deactivate account',
  nav_faq: 'Frequently Asked Questions',
  nav_information: 'Information',
  nav_library: 'Library',
  nav_notice: 'Notice',
  nav_privacy_policy: 'Privacy Policy',
  nav_return_my_menu: 'Return to My Menu Top',
  nav_support: 'Support',
  nav_term_of_service: 'Terms of Service',
  nav_user_info: 'User information',
  notice_forgot_password_email_sent: 'Sent confirmation email',
  notice_otp_email_sent: 'We are sending you an email with the verification code. Please check your inbox and enter your verification code. ',
  title_cash_flow: 'Cash flow',
  title_change_email: 'Change Email Address',
  title_change_password: 'Change password',
  title_config_font: 'Change font size',
  title_config_push_notification: 'Push notification',
  title_config_security: 'Security',
  title_forgot_password: 'Forgot password',
  title_graph_simulation: 'Simulation graph',
  title_login: 'Login',
  title_my_menu: 'My Menu',
  title_notification: 'Notification',
  title_signup: 'New Member Registration',
  title_user_deactivation: 'Account deactivation',
  title_user_info: 'User Information',
  title_tax_list: 'Final tax return',
  validation_email: 'The format of the email address you entered is incorrect. ',
  validation_password_character: 'Only the following character types can be used for passwords. \n Half-width lowercase letters, half-width uppercase letters, half-width numbers, symbols !_/`()+-=$#&@~',
  validation_password_confirm: 'Password and password (confirmation) do not match. ',
  validation_password_max: 'Enter the password using at least 64 characters. ',
  validation_password_min: 'Enter at least 8 characters for the password. ',
  following_materials: 'The following materials are required. If you can confirm it, please check it.',
  following_materials_are_ready: 'When the materials are ready, tap the "Material preparation complete" button to proceed to the next page. \\n\\n*By checking all of the above, you will be able to tap the "Document preparation complete" button.',
  check_all: 'Check all',
  inform_tax_1: 'Thank you for your reply. At our company, ...',
  inform_tax_2: 'If you would like to request a tax return, please tap the button below.',
  undertake_final_tax: 'If you would like to request tax return support for the {financial_year} fiscal year ({financial_year_ja}), please tap the "Request" button below.\nIf you do not need tax return support, please tap "Do not request".',
  prepared: 'Prepared by {day}/{month}/{year}',
  back_to_final_tax: 'Return to the final tax',
  unread: 'Unread',
  mark_read: 'Mark all as read',
  final_year: 'Final tax return status for {final_year} ({final_year_ja})',
  has_error: 'An error has occurred. Please try again.',
  has_error_network: 'Please try again in an environment where the network is stable.',
  error_account_register: 'The account was registered. Please try with another account.',
  no_tax: 'There are currently no tax returns to choose from',
  otp_desc: 'Account verification is valid for one hour from reception.'
}
