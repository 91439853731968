import Vue from 'vue'
import Router from 'vue-router'
import {routes} from '@/router/routes'
import {triggerMobileAction, triggerEvent} from '@/utils/webview.util'
import * as mobileEvent from '@/enums/mobile-event.enum'
import * as webEvent from '@/enums/web-event.enum'
import {snakeCase} from 'lodash-es'

Vue.use(Router)

const router = new Router({
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  // update localize if there is locale param
  if (to.query.locale) {
    triggerEvent(webEvent.CHANGE_LANGUAGE, {
      locale: to.query.locale
    })
  }

  if (from.query.platform && !to.query.platform) {
    to.query.platform = from.query.platform
    next({ ...to })
    return
  }

  // trigger page redirect event
  triggerMobileAction(mobileEvent.PAGE_REDIRECT, {
    page: snakeCase(to.name).toUpperCase()
  })

  next()
})

export default router
