import {PAGE_READY, TOKEN_EXPIRED} from '@/enums/mobile-event.enum'
import {triggerMobileAction, addEventListener, removeEventListener} from '@/utils/webview.util'
import {DEVICE_CONFIG, KEYBOARD, USER_TOKEN} from '@/enums/web-event.enum'
import {snakeCase} from 'lodash-es'

export default {
  inject: ['Page'],
  data () {
    return {
      webview_scrollToInputOnFocused: false,
      webview_scrollToInputOffset: 0,
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.preventLongPressMenu(document.querySelectorAll('input'))
  },
  beforeDestroy () {
    removeEventListener(USER_TOKEN, this.onUserTokenGiven)
    removeEventListener(DEVICE_CONFIG, this.updateAppConfig)
    if (this.webview_scrollToInputOnFocused) {
      removeEventListener(KEYBOARD, this.onKeyboardUpdated)
    }
  },
  methods: {
    absorbEvent (event) {
      var e = event || window.event
      // e.preventDefault && e.preventDefault()
      // e.stopPropagation && e.stopPropagation()
      e.cancelBubble = true
      e.returnValue = true
      return true
    },
    preventLongPressMenu (nodes) {
      for (var i = 0; i < nodes.length; i++) {
        nodes[i].ontouchstart = this.absorbEvent
        nodes[i].ontouchmove = this.absorbEvent
        nodes[i].ontouchend = this.absorbEvent
        nodes[i].ontouchcancel = this.absorbEvent
      }
    },
    init () {
      this.registerEventListeners()
    },
    registerEventListeners () {
      addEventListener(USER_TOKEN, this.onUserTokenGiven)
      addEventListener(DEVICE_CONFIG, this.updateAppConfig)

      if (this.webview_scrollToInputOnFocused) {
        addEventListener(KEYBOARD, this.onKeyboardUpdated)
      }

      // overwrite this function, but keep calling notifyMobilePageReady
      this.notifyMobilePageReady()
    },
    onUserTokenGiven (e) {
      if (e && e.detail && e.detail.token) {
        this.$store.commit('auth/setToken', {token: e.detail.token})
      }
    },
    notifyMobilePageReady () {
      triggerMobileAction(PAGE_READY, {
        page: snakeCase(this.$route.name).toUpperCase()
      })
    },
    logout () {
      triggerMobileAction(TOKEN_EXPIRED, {})
    },
    onKeyboardUpdated (e) {
      if (e && e.detail) {
        const toggle = !!e.detail.toggle
        const el = document.querySelector('input:focus')
        if (toggle && el && this.webview_scrollToInputOnFocused) {
          const ratio = e.detail.ratio ?? 0
          this.$store.commit('setKeyboardHeight', window.innerHeight * ratio)

          this.$nextTick(() => {
            const elTop = el.getBoundingClientRect().top
            const elHeight = el.clientHeight
            scroll({top: elTop - elHeight + this.webview_scrollToInputOffset, behavior: 'auto'})
          })
        } else {
          this.$store.commit('setKeyboardHeight', 0)
        }
      }
    },
    updateAppConfig (e) {
      if (e?.detail?.version) {
        this.$store.commit('config/updateAppConfig', {version: e.detail.version})
      }
    },
  }
}
