import { render, staticRenderFns } from "./TaxDocumentSubmission.vue?vue&type=template&id=78218eca&scoped=true&"
import script from "./TaxDocumentSubmission.vue?vue&type=script&lang=js&"
export * from "./TaxDocumentSubmission.vue?vue&type=script&lang=js&"
import style0 from "./TaxDocumentSubmission.vue?vue&type=style&index=0&id=78218eca&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78218eca",
  null
  
)

export default component.exports