<template>
  <div class="change-email-email">
    <div class="page-body">
      <div class="change-email-email__icon">
        <img
          src="/images/icons/icon-feather-mail.svg"
          alt="Password icon"
          width="31px"
        >
      </div>
      <div
        class="change-email-email__text"
        v-html="nl2br($t('desc_please_enter_new_email'))"
      />
      <div class="change-email-email__input">
        <input
          ref="passwordInputEl"
          v-model="form.email"
          type="email"
          :placeholder="$t('common_email_address')"
          :class="{'is-invalid': form.errors && form.errors.email}"
        >
        <div class="invalid-feedback">
          {{ form.errors && form.errors.email && form.errors.email[0] }}
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="change-email-email__submit">
        <button
          class="btn btn-primary"
          :disabled="!canSubmit"
          @click="submit"
        >
          {{ $t('common_register') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {nl2br} from '@/utils/string.util'
import {validateEmail} from '@/utils/validate.util'
import {RequestError} from '@/errors/request.error'
import {mapGetters} from 'vuex'
import {UserService} from '@/services/user.service'
import {triggerMobileAction} from '@/utils/webview.util'
import {TOKEN_NEW} from '@/enums/mobile-event.enum'

export default {
  name: 'ChangeEmailEmail',
  mixins: [WebviewMixin],
  data () {
    return {
      form: {
        email: null,
        errors: {}
      },
      userToken: null
    }
  },
  computed: {
    canSubmit () {
      return this.hasToken &&
        this.form.email &&
        !this.loading
    },
    ...mapGetters({
      hasToken: 'auth/hasToken',
      currentUser: 'auth/currentUser',
      loading: 'loadingAny'
    })
  },
  methods: {
    nl2br,
    validate () {
      this.form.errors = {}
      let emailError = validateEmail(this.form.email)
      if (emailError) {
        this.form.errors.email = [emailError]
      }
      return !Object.keys(this.form.errors).length
    },
    async submit () {
      let sentOTPResult

      if (!this.validate()) return

      try {
        sentOTPResult = await UserService.sendNewEmailOTP(this.form)
      } catch (e) {
        if (e instanceof RequestError) {
          this.form.errors = e.details
          if (!e.details.email) {
            this.form.errors = {
              email: [e.message]
            }
            return
          }
        }
        throw e
      }

      this.$store.commit('auth/setToken', sentOTPResult.data)
      this.$store.commit('user/setTempNewEmail', sentOTPResult.data?.email)

      // Send new token to mobile
      if (sentOTPResult.data?.token) {
        triggerMobileAction(TOKEN_NEW, {
          token: sentOTPResult.data.token
        })
      }

      await this.$router.replace({name: this.Page.ChangeEmailOtp, query: {...this.$route.query}})
    },
  }
}
</script>

<style scoped lang="scss">
.change-email-email {
  font-size: 14px;

  .page-body {
    padding-top: 68px;
  }

  &__icon {
    margin-bottom: 39px;
    text-align: center;
  }

  &__text {
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
  }

  &__input {
    margin-bottom: 50px;
    input {
      height: 32px;
      padding: 0 5px;
    }
  }

  &__submit {
    margin-bottom: 40px;
  }
}
</style>
