import {REQUEST} from '@/enums/web-error.enum'

export class RequestError extends Error {
  constructor ({message, status, details = {}}) {
    super(message)
    this.code = REQUEST
    this.status = status
    this.details = details || {}
  }
}
