import {LOAD_INTERNAL_LINK, SHOW_TOAST} from '@/enums/mobile-event.enum'

const sendLogToMobile = (eventName) => {
  return (e) => {
    triggerMobileAction(`Receive event [${eventName}] from mobile with details: ${JSON.stringify(e.detail || {})}`, {})
  }
}

const addEventListener = (eventName, callback) => {
  document.addEventListener(eventName, callback, true)
  document.addEventListener(eventName, sendLogToMobile(eventName), true)
}

const removeEventListener = (eventName, callback) => {
  document.removeEventListener(eventName, callback, true)
  document.removeEventListener(eventName, sendLogToMobile(eventName), true)
}

const triggerEvent = (eventName, payload) => {
  document.dispatchEvent(
    new CustomEvent(eventName, {
      detail: payload
    })
  )
}

const $_triggerIos = (eventName, payload) => {
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.eventMessageHandler) {
    window.webkit.messageHandlers.eventMessageHandler.postMessage(
      JSON.stringify({
        event: eventName,
        payload
      })
    )
  }
}

const $_triggerAndroid = (eventName, payload) => {
  if (window.android) {
    window.android.triggerEvent(
      JSON.stringify({
        event: eventName,
        payload
      })
    )
  }
}

const triggerMobileAction = (eventName, payload = {}) => {
  console.log({
    timestamp: Date.now(),
    dataForMobile: {
      event: eventName,
      payload
    }
  })
  try {
    $_triggerIos(eventName, payload)
    $_triggerAndroid(eventName, payload)
  } catch (e) {
    console.error(e)
  }
}

const showToast = (message, options = {}) => {
  triggerMobileAction(SHOW_TOAST, {
    message,
    options
  })
}

const openExternalLink = (url, title) => {
  if (url) {
    triggerMobileAction(LOAD_INTERNAL_LINK, {
      url,
      title
    })
  }
}

export {
  addEventListener,
  removeEventListener,
  triggerEvent,
  triggerMobileAction,
  showToast,
  openExternalLink,
}
