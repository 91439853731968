<template>
  <div class="register-customer-code">
    <div class="page-body">
      <div
        class="register-customer-code__title"
        v-html="nl2br($t('desc_please_enter_customer_code_birthday'))"
      />
      <div class="register-customer-code__input">
        <div class="register-customer-code__input__code">
          <label class="relative">
            <span>{{ $t('common_customer_code') }}</span>
            <img
              src="/images/icons/icon-ionic-ios-information-circle.svg"
              width="11px"
              @click="showCustomerCodeInfo = true"
            >
            <template v-if="showCustomerCodeInfo">
              <div
                class="register-customer-code__input__code__info"
                @click="showCustomerCodeInfo = false"
                v-html="nl2br($t('common_customer_code_label_info'))"
              />
              <div
                class="register-customer-code__input__code__backdrop"
                @click="showCustomerCodeInfo = false"
              />
            </template>
          </label>
          <input
            v-model="form.customerCode"
            type="text"
            :class="{'is-invalid': form.errors && form.errors.customerCode}"
          >
          <div class="invalid-feedback">
            {{ form.errors && form.errors.customerCode && form.errors.customerCode[0] }}
          </div>
        </div>
        <div class="register-customer-code__input__birthday">
          <label>
            <span>{{ $t('common_birthday') }}</span>
          </label>
          <div
            class="relative"
            :class="{'is-invalid': form.errors && form.errors.birthday}"
          >
            <input
              ref="birthdayInputEl"
              v-model="form.birthday"
              type="text"
              placeholder="1990/1/1"
              :class="{'is-invalid': form.errors && form.errors.birthday}"
              @focus.prevent="showBirthdaySelectBox"
            >
            <img
              src="/images/icons/icon-arrow-right-gold.svg"
              width="9px"
            >
          </div>
          <div class="invalid-feedback">
            {{ form.errors && form.errors.birthday && form.errors.birthday[0] }}
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="register-customer-code__submit">
        <button
          class="btn"
          :disabled="!validated"
          @click="submit"
        >
          {{ $t('common_register') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {nl2br} from '@/utils/string.util'
import {triggerMobileAction, addEventListener, showToast} from '@/utils/webview.util'
import {SELECT_BIRTHDAY, WEBVIEW_MODEL_HIDE, WEBVIEW_MODEL_SHOW} from '@/enums/mobile-event.enum'
import {SELECTED_BIRTHDAY, USER_TOKEN} from '@/enums/web-event.enum'
import {mapGetters} from 'vuex'
import {AuthService} from '@/services/auth.service'
import {RequestError} from '@/errors/request.error'
import {HTTP_UNAUTHORIZED} from '@/enums/http-code.enum'
import AuthMixin from '@/mixins/auth.mixin'

export default {
  name: 'RegisterCustomerCode',
  mixins: [
    WebviewMixin,
    AuthMixin
  ],
  data () {
    return {
      form: {
        customerCode: null,
        birthday: null,
        errors: {}
      },
      showCustomerCodeInfo: false
    }
  },
  computed: {
    validated () {
      return this.hasToken && this.form.customerCode && this.form.birthday
    },
    ...mapGetters({
      hasToken: 'auth/hasToken',
      currentUser: 'auth/currentUser'
    })
  },
  watch: {
    showCustomerCodeInfo (val) {
      triggerMobileAction(val ? WEBVIEW_MODEL_SHOW : WEBVIEW_MODEL_HIDE, {})
    }
  },
  created () {
    if (this.currentUser?.birthday) {
      this.form.birthday = this.currentUser.birthday
    }
  },
  methods: {
    nl2br,
    onUserTokenGiven () {
      // do nothing
    },
    registerEventListeners () {
      addEventListener(USER_TOKEN, this.onUserTokenGiven)
      addEventListener(SELECTED_BIRTHDAY, this.onBirthdaySelected)
      this.notifyMobilePageReady()
    },
    onBirthdaySelected (e) {
      if (e && e.detail && e.detail.birthday) {
        this.form.birthday = e.detail.birthday
      }
    },
    async submit () {
      let registerResult
      try {
        registerResult = await AuthService.submitCustomerCode(this.form)
      } catch (e) {
        if (!(e instanceof RequestError)) {
          throw e
        }

        // Redirect to Login if the key is expired
        if (e.status === HTTP_UNAUTHORIZED) {
          showToast(e.message)
          this.$router.push({name: this.Page.Register})
          return
        }

        // Show form error
        this.form.errors = e.details
        if (!Object.keys(e.details).length) {
          this.form.errors = {
            customerCode: [e.message]
          }
        }
        return
      }

      this.onAuthenticated(registerResult.data)
    },
    showBirthdaySelectBox () {
      this.$refs.birthdayInputEl.blur()
      triggerMobileAction(SELECT_BIRTHDAY, {})
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
.register-customer-code {
  font-size: 14px;
  > *:not(.page-body):not(.page-footer) {
    display: none;
  }

  .page-body {
    padding-top: 60px;
    @media (max-width: 320px){
      padding-top: 15px;
    }
  }

  &__title {
    font-size: 4.8vw;
    text-align: center;
    margin-bottom: 74px;
    line-height: 28px;
  }

  &__input {
    padding-bottom: 135px;
    @media (max-width: 320px){
      padding-bottom: 100px;
    }

    input {
      font-size: 4.27vw;
      padding-left: 7px;
      padding-right: 7px;
    }
    label {
      margin-bottom: 5px;
      padding: 0 7px;
      span {
        font-size: 4.27vw;
      }
    }
    &__code {
      margin-bottom: 20px;
      label {
        img {
          margin-left: 5px;
          cursor: pointer;
        }
      }

      &__info {
        position: absolute;
        width: 201px;
        background-color: black;
        color: white;
        padding: 10px;
        z-index: 2;
        opacity: 0.7;
        line-height: 1.5;
      }

      &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
        opacity: 0.4;
        z-index: 1;
      }
    }

    &__birthday {
      img {
        position: absolute;
        right: 8px;
        top: 10px;
        transform: rotate(
            90deg
        );
      }
    }
  }

  &__submit {
    padding-bottom: 40px;
    .btn {
      width: 100%;
      border: none;
      background-color: $btn-orange;
      color: $color-default-lightest;
      padding: 13px 15px;
      font-size: 3.733vw;
      &:focus {
        outline: none;
      }
      &:disabled {
        pointer-events: none;
        background-color: $bg-gray;
        color: $color-default-darkest;
      }
    }
  }
}
</style>
