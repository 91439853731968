<template>
  <div class="tax-progress screen-body--bg">
    <div class="page-body">
      <tax-document-submission style="margin-bottom: 30px" />

      <tax-contact-block style="margin-bottom: 30px" />

      <div class="tax-progress__customer-code">
        {{ $t('desc_customer_code', {code: currentUser.customer_code}) }}
      </div>
      <div class="tax-progress__title">
        {{ $t('final_year', {final_year: finalYear, final_year_ja: finalYearJa}) }}
      </div>
      <div class="tax-progress__timeline">
        <div
          v-for="item in data"
          :key="item.id"
          :class="{'active': item.date}"
          class="tax-progress__timeline__item"
        >
          <span
            class="tax-progress__timeline__item__status"
            v-html="item.name"
          />
          <span
            v-if="item.date"
            class="tax-progress__timeline__item__date"
          >{{ item.date | formatDate }}</span>
        </div>
      </div>
      <fieldset class="tax-progress__status">
        <legend class="tax-progress__status__title">
          <span>{{ $t('desc_from_makea_office') }}</span>
        </legend>
        <div
          class="tax-progress__status__comment"
          v-html="currentStatus.comment"
        />
      </fieldset>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {addEventListener, removeEventListener, triggerMobileAction} from '@/utils/webview.util'
import {USER_TOKEN} from '@/enums/web-event.enum'
import {TaxService} from '@/services/tax.service'
import {dynamicSort} from '@/utils/common.util'
import { mapGetters } from 'vuex'
import {OPEN_BROWSER, OPEN_WEBVIEW} from '@/enums/mobile-event.enum'
import TaxContactBlock from '@/views/pages/Tax/components/TaxContactBlock'
import TaxDocumentSubmission from '@/views/pages/Tax/components/TaxDocumentSubmission.vue'
import {nl2br} from '@/utils/string.util'

export default {
  name: 'TaxProgress',
  components: {
    TaxContactBlock,
    TaxDocumentSubmission,
  },
  mixins: [WebviewMixin],
  data () {
    return {
      data: [],
      currentStatus: {},
    }
  },
  computed: {
    finalYear () {
      return this.$route.query.financial_year
    },
    finalYearJa () {
      return this.$route.query.financial_year_ja
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      appInfo: 'config/appInfo',
      hasPlatform: 'config/hasPlatform',
    })
  },
  created () {
    addEventListener(USER_TOKEN, this.initData)
    this.$store.dispatch('config/getAppGeneralInfo')
  },
  beforeDestroy () {
    removeEventListener(USER_TOKEN, this.initData)
  },
  methods: {
    nl2br,
    initData (e) {
      if (e?.detail?.token) {
        this.$store.dispatch('auth/getCurrentUserInfo')
        TaxService.getStatusSession(this.$route.params.id).then(res => {
          this.data = res.sort(dynamicSort('asc', 'weight', 'id'))
        })
        TaxService.getStatusComment(this.$route.params.id).then(res => {
          this.currentStatus = res
        })
      }
    },
    openLinkInBrowser (url) {
      if (this.hasPlatform) {
        triggerMobileAction(OPEN_BROWSER, {
          url
        })
      } else {
        window.location.href = url
      }
    },
    openLinkInWebview (url) {
      if (this.hasPlatform) {
        triggerMobileAction(OPEN_WEBVIEW, {
          url
        })
      } else {
        window.location.href = url
      }
    },
  }
}
</script>

<style
  scoped
  lang="scss"
>
.tax-progress {
  line-height: 1.5;

  .page-body {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  &__customer-code {
    font-size: 12px;
  }

  &__title {
    padding-bottom: 9px;
    margin-bottom: 13px;
    border-bottom: 1px solid #E8E8E8;
    font-size: 16px;
    font-weight: bold;
  }

  &__timeline__item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 20px;
    position: relative;
    color: #DDDDDD;
    font-size: 16px;
    padding-bottom: 26px;

    &.active {
      color: #575757;

      &::before {
        background-color: #575757;
      }

      &::after {
        background-color: #575757;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      bottom: 0;
      width: 9px;
      height: 9px;
      background-color: #EBEBEB;
      border-radius: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      left: 4px;
      top: 10px;
      width: 1px;
      height: 100%;
      background-color: #EBEBEB;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &__status {
      font-size: 16px;
      flex-grow: 1;
      max-width: calc(100% - 65px);
      position: relative;
      top: -3px;
    }

    &__date {
      font-size: 12px;
      width: 65px;
      text-align: right;
    }
  }

  &__status {
    padding: 18px 10px 18px 10px;
    border-radius: 5px;
    margin-bottom: 30px;

    &__title {
      position: relative;

      span {
        position: absolute;
        white-space: nowrap;
        background-color: #F8F8F8;
        padding: 0 3px 0 6px;
        top: -12px;
        left: -12px;
        font-weight: bold;
      }
    }

    &__comment {
      color: #666565;
      font-size: 12px;
    }
  }
}
</style>
