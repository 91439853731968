import {UNEXPECTED} from '@/enums/web-error.enum'

export class UnexpectedError extends Error {
  constructor ({message, url = null, lineNo = null, columnNo = null, error = null}) {
    super(message)
    this.code = UNEXPECTED
    this.url = url
    this.lineNo = lineNo
    this.columnNo = columnNo
    this.error = error
  }
}
