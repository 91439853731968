<template>
  <div class="user-deactivation">
    <div class="page-body">
      <div class="user-deactivation__title">
        {{ $t('desc_deactivation_title') }}
      </div>
      <div
        class="user-deactivation__description"
        v-html="nl2br($t('desc_deactivation'))"
      />
      <div class="user-deactivation__submit">
        <button
          class="btn btn-primary"
          :disabled="!canSubmit"
          @click="confirmDeactivate"
          v-text="$t('desc_deactivation_btn')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {addEventListener, removeEventListener, triggerMobileAction} from '@/utils/webview.util'
import {USER_TOKEN} from '@/enums/web-event.enum'
import {CONFIRM_DEACTIVATE} from '@/enums/mobile-event.enum'
import {mapGetters} from 'vuex'
import {nl2br} from '@/utils/string.util'

export default {
  name: 'UserChangeInfo',
  mixins: [WebviewMixin],
  computed: {
    canSubmit () {
      return !this.loading
    },
    ...mapGetters({
      loading: 'loadingAny',
      hasPlatform: 'config/hasPlatform',
    })
  },
  created () {
    addEventListener(USER_TOKEN, this.initData)
  },
  beforeDestroy () {
    removeEventListener(USER_TOKEN, this.initData)
  },
  methods: {
    nl2br,
    initData (e) {
      if (e?.detail?.token) {
        this.$store.dispatch('auth/getCurrentUserInfo')
      }
    },
    confirmDeactivate () {
      if (this.hasPlatform) {
        triggerMobileAction(CONFIRM_DEACTIVATE, {})
      } else {
        this.$store.dispatch('auth/logout')
      }
    },
  }
}
</script>

<style
  scoped
  lang="scss"
>
.user-deactivation {
  .page-body {
    margin: 0 -10px 20px;
    width: calc(100% + 20px);
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__description {
    margin-bottom: 40px;
    line-height: 1.5;
  }

  .btn {
    border-radius: 5px;
  }
}
</style>
