<template>
  <div class="forgot-password">
    <div class="page-body">
      <div class="forgot-password__icon">
        <img
          src="/images/icons/icon-simple-minutemailer.svg"
          alt="Email icon"
          width="43px"
        >
      </div>
      <div class="forgot-password__text">
        <div class="forgot-password__text__email">
          "{{ tempNewEmail }}"
        </div>
        <div class="forgot-password__text__title">
          {{ $t('notice_forgot_password_email_sent') }}
        </div>
        <div
          class="forgot-password__text__desc"
          v-html="nl2div($t('desc_note_new_password_in_email'))"
        />
      </div>
    </div>
    <div class="page-footer">
      <div class="forgot-password__submit">
        <router-link
          v-slot="{ navigate }"
          :to="{name: Page.Login}"
          custom
        >
          <button
            class="btn btn-primary"
            @click="navigate"
          >
            {{ $t('nav_back_to_login') }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {nl2div} from '@/utils/string.util'
import {mapGetters} from 'vuex'

export default {
  name: 'ForgotPasswordOtp',
  mixins: [WebviewMixin],
  computed: mapGetters({
    tempNewEmail: 'user/tempNewEmail'
  }),
  methods: {
    nl2div
  }
}
</script>

<style
  scoped
  lang="scss"
>
.forgot-password {
  &__icon {
    padding-top: 70px;
    margin-bottom: 32px;
    text-align: center;
  }
  &__text {
    text-align: center;
    margin-bottom: 50px;
    &__email {
      margin-bottom: 19px;
      font-size: 5.33vw;
      color: var(--color-text-title);
      word-break: break-all;
    }
    &__title {
      margin-bottom: 49px;
      font-size: 5.33vw;
      color: var(--color-text-title);
    }
    &__desc {
      line-height: 1.5;
      color: var(--color-text-desc);
      font-weight: 300;
      font-size: 3.733vw;
    }
  }
  &__input {
    margin-bottom: 50px;
  }
  &__submit {
    padding-bottom: 50px;
    .btn {
      font-size: 3.733vw;
      padding: 12px 0;
    }
  }
}
</style>
