<template>
  <div class="change-password">
    <div class="page-body">
      <div class="change-password__icon">
        <img
          src="/images/icons/icon-feather-unlock.svg"
          alt="Password icon"
          width="27px"
        >
      </div>
      <div
        class="change-password__text"
        v-html="nl2br($t('desc_please_enter_login_password'))"
      />
      <div class="change-password__input">
        <div>
          <div
            class="relative"
            :class="{'is-invalid': form.errors && form.errors.password}"
          >
            <input
              ref="passwordInputEl"
              v-model="form.password"
              :type="form.options.showPassword ? 'text' : 'password'"
              :class="{'is-invalid': form.errors && form.errors.password}"
              :placeholder="$t('common_password')"
            >
            <template>
              <img
                v-if="form.options.showPassword"
                src="/images/icons/icon-feather-eye.svg"
                width="14px"
                alt="Show password"
                @click="form.options.showPassword = false, $refs.passwordInputEl.focus()"
              >
              <img
                v-else
                src="/images/icons/icon-feather-eye-off.svg"
                width="14px"
                alt="Hide password"
                @click="form.options.showPassword = true, $refs.passwordInputEl.focus()"
              >
            </template>
          </div>
          <div
            class="invalid-feedback"
            v-html="form.errors && form.errors.password && nl2br(form.errors.password[0])"
          />
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="change-password__submit">
        <button
          class="btn btn-primary"
          :disabled="!canSubmit"
          @click="submit"
        >
          {{ $t('common_confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {nl2br} from '@/utils/string.util'
import {mapGetters} from 'vuex'
import {UserService} from '@/services/user.service'
import {RequestError} from '@/errors/request.error'
import {TOKEN_NEW} from '@/enums/mobile-event.enum'
import {triggerMobileAction} from '@/utils/webview.util'

export default {
  name: 'ChangePassword',
  mixins: [WebviewMixin],
  data () {
    return {
      form: {
        password: null,
        options: {
          showPassword: false
        },
        errors: {}
      }
    }
  },
  computed: {
    canSubmit () {
      return this.hasToken && this.form.password && !this.loading
    },
    ...mapGetters({
      hasToken: 'auth/hasToken',
      loading: 'loadingAny'
    })
  },
  methods: {
    nl2br,
    async submit () {
      // send request
      let verifyResult
      try {
        verifyResult = await UserService.verifyPasswordToChangePassword(this.form)
      } catch (e) {
        if (e instanceof RequestError) {
          // Show form error
          this.form.errors = e.details
          if (!e.details.password) {
            this.form.errors = {
              password: [e.message]
            }
            return
          }
        }
        throw e
      }

      // Set new token for later requests
      this.$store.commit('auth/setToken', verifyResult.data)

      // Send new token to mobile
      if (verifyResult.data?.token) {
        triggerMobileAction(TOKEN_NEW, {
          token: verifyResult.data.token
        })
      }

      this.$router.push({name: this.Page.ChangePasswordNew})
    }
  }
}
</script>

<style scoped lang="scss">
.change-password {
  font-size: 14px;

  .page-body {
    padding-top: 68px;
  }

  &__icon {
    margin-bottom: 36px;
    text-align: center;
  }

  &__text {
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
  }

  &__input {
    margin-bottom: 50px;
    input {
      height: 32px;
      padding: 0 25px 0 5px;
    }

    img {
      position: absolute;
      right: 7px;
      top: 9px;
    }
  }

  &__submit {
    margin-bottom: 40px;
  }
}
</style>
