export default {
  btn_enter_email: 'メールアドレスを入力する',
  btn_add_friend: '友だち追加',
  common_already_had_account_login: 'すでにアカウントをお持ちの方　ログイン',
  common_app_version: 'バージョン　{version}',
  common_birthday: '生年月日',
  common_click_if_forgot_password: 'パスワード忘れた場合はこちら',
  common_confirm: '確認',
  common_customer_code: 'お客様コード',
  common_customer_code_label_info: 'こちらはトラスティーパートナーズ株式会社の顧客様へ配布しているコードです。\nご不明点ございましたらお問い合わせください。',
  common_customer_number: 'お客様コード',
  common_email_address: 'メールアドレス',
  common_enable_device_authentication: '端末の認証を有効にする',
  common_enable_device_authentication_desc: 'アプリログインのセキュリティを強化できます',
  common_enable_notifications: '通知を有効にする',
  common_fullname: '名前',
  common_login: 'ログイン',
  common_login_configuration: '認証',
  common_login_with_sns: '外部アカウントでログイン',
  common_logout: 'ログアウト',
  common_notification: '通知',
  common_ok: 'OK',
  common_password: 'パスワード',
  common_new_password: '新しいパスワード',
  common_password_confirm: 'パスワード（確認）',
  common_password_rule_desc: '※半角英数字8文字以上',
  common_please_enter_single_byte: '半角数字で入力してください',
  common_register: '登録',
  common_register_from_here: '新規会員登録はこちらから',
  common_register_with_sns: '外部アカウントで新規登録',
  common_resend_otp_after_seconds: '{seconds}秒後に認証コードが再送信可能です',
  common_resend_otp_email: '確認メール再送信',
  common_resend_otp_email_after_seconds: '{seconds}秒後に認証コードが再送信可能です',
  common_reset_password: 'パスワード再設定',
  common_save: '保存',
  common_login_with_facebook: 'Facebookでログイン',
  common_login_with_google: 'Googleでログイン',
  common_login_with_apple: 'Appleでサインイン',
  common_sama: ' 様',
  common_here: 'こちら',
  tax_button: '今年度の確定申告依頼',
  confirmation_report_submit: '依頼する',
  confirmation_information_ready: '資料準備完了',
  confirmation_report_back: '依頼しない',
  desc_annual_tax_return: '{content}年度({final_year})確定申告',
  desc_completed_reset_email: 'メールアドレスを\n設定しました',
  desc_completed_reset_password: 'パスワード変更\n完了いたしました',
  desc_customer_code: 'お客様コード: {code}',
  desc_deactivation_title: 'makeAを退会しますか？',
  desc_deactivation: '退会すると、アカウントが削除されます。\n本当に退会されますか？',
  desc_deactivation_btn: '退会手続きを進める',
  desc_from_makea_office: 'makeA運営事務局より',
  desc_note_new_password_in_email: '新しいパスワードをメールにて\n送信しました。\nそのパスワードでログインし、パスワードを\n変更して下さい。',
  desc_please_enter_customer_code_birthday: 'お客様コードと生年月日を\nご入力ください',
  desc_please_enter_login_password: 'ログインパスワードを\n入力してください',
  desc_please_enter_new_email: 'メールアドレスを\n入力してください',
  desc_please_enter_registered_email: 'ご登録のメールアドレスを\nご記入してください',
  desc_please_enter_your_password: 'パスワードを登録してください',
  desc_tax_return_introduce: '{financial_year}年度({financial_year_ja})の確定申告サポートをご希望の方は下記「依頼する」ボタンをタップしご依頼ください。\n確定申告サポートが不要の場合は「依頼しない」のタップをお願い致します。',
  desc_tax_return_introduce_sub_1: '※メールにてご案内の書類（データ）をお送り致します。',
  desc_tax_return_introduce_sub_2_1: '※受信するメールアドレスは',
  desc_tax_return_introduce_sub_2_2: 'から設定/変更出来ます',
  desc_tax_contact_header: '「確定申告サポート」に関するお問い合わせ',
  desc_tax_contact_note: '弊社営業担当へ直接ご連絡ください（推奨）',
  desc_tax_contact_line_header: '公式LINEからのお問い合わせ',
  desc_tax_contact_line_content_1: '確定申告専用窓口（LINE）',
  desc_tax_contact_line_content_2: 'LINE友だち追加はこちら→',
  desc_tax_contact_phone_header: 'お電話でのお問い合わせ',
  desc_tax_contact_phone_time_range: '（受付時間 平日9:30～18:00 土日祝日除く）',
  desc_tax_contact_send_mail_notice: 'ご登録のメールアドレス宛に、ご案内の書類を\n送付いたしました。ご確認お願いいたします。',
  desc_tax_contact_methods_header: 'ご準備頂きました確定申告用の資料は\n下記方法のいずれかにて弊社までご送付ください',
  desc_tax_contact_method_google_drive_header: '①Googleドライブ【{customerName}様専用】へデータ格納',
  desc_tax_contact_method_google_drive_url: 'Googleドライブを開く',
  desc_tax_contact_method_email_header: '②メールでデータ送付する',
  desc_tax_contact_method_email_desc: '送付先メールアドレス',
  desc_tax_contact_method_address_header: '③郵送で送付する',
  desc_tax_contact_method_address_content: '〒150-0013\n東京都渋谷区恵比寿4-3-14 恵比寿SSビル 8階\nトラスティーパートナーズ株式会社\n経営管理事業部宛',
  desc_tax_email_provide_notice_1: '確定申告サポートにあたりメールにて書類一式をお送りいたします。',
  desc_tax_email_provide_notice_2: '外部アカウントでログインされていますので、書類送付するメールアドレスのご登録をお願い致します。',
  desc_tax_email_provide_notice_3: '※一度設定頂きますと次回以降は設定されたメールアドレスに書類送付されます。',
  desc_tax_email_provide_notice_4: '※メールアドレスはいつでも変更することが可能です。',
  desc_tax_email_provide_notice_5: '※ここで設定するメールアドレスはログイン用のメールアドレスではありません。引き続き外部アカウントにてログイン出来ます。',
  nav_about_app: 'アプリについて',
  nav_back_to_login: 'ログインへ戻る',
  nav_change_email: 'メールアドレス変更',
  nav_change_password: 'パスワード変更',
  nav_column: 'コラム',
  nav_company_info: '運営会社',
  nav_config: '設定',
  nav_config_push_notification: 'プッシュ通知設定',
  nav_config_security: 'セキュリティ',
  nav_contact_us: 'お問い合わせ',
  nav_deactivate: '退会について',
  nav_faq: 'よくある質問',
  nav_information: '情報',
  nav_library: 'ライブラリー',
  nav_notice: 'お知らせ',
  nav_privacy_policy: 'プライバシーポリシー',
  nav_return_my_menu: 'マイメニュートップへ戻る',
  nav_support: 'サポート',
  nav_term_of_service: '利用規約',
  nav_user_info: 'ユーザー情報',
  notice_forgot_password_email_sent: '確認メールを送信致しました',
  notice_otp_email_sent: '認証コードを記載したメールを\nお送りしております。\n受信箱をご確認の上、認証コードを\nご入力ください。',
  title_cash_flow: 'キャッシュフロー',
  title_change_email: 'メールアドレス変更',
  title_change_password: 'パスワード変更',
  title_config_font: '文字サイズの変更',
  title_config_push_notification: 'プッシュ通知',
  title_config_security: 'セキュリティ',
  title_forgot_password: 'パスワードお忘れの方',
  title_graph_simulation: 'シミュレーショングラフ',
  title_login: 'ログイン',
  title_my_menu: 'マイメニュー',
  title_notification: 'お知らせ',
  title_signup: '新規会員登録',
  title_user_deactivation: '退会について',
  title_user_info: 'ユーザー情報',
  title_tax_list: '確定申告',
  validation_email: '入力したメールアドレスの形式が間違っています。',
  validation_password_character: 'パスワードには以下の文字種のみ使用可能です。\n 半角アルファベット小文字、半角アルファベット大文字、半角数字、記号!_/`()+-=$#&@~',
  validation_password_confirm: 'パスワードとパスワード(確認)が一致しません。',
  validation_password_max: 'パスワードは64文字以下で入力してください。',
  validation_password_min: 'パスワードは8文字以上入力してください。',
  following_materials: '下記資料が必要です。確認ができましたらチェックを入れてください。',
  following_materials_are_ready: '資料の準備が整いましたら、「資料準備完了」ボタンを タップして次のページへお進みください。\n\n※上記にチェックが全てつくことで、「資料準備完了」ボタンがタップ出来る様になります',
  check_all: '全てチェック',
  inform_tax_1: 'ご回答ありがとうございます。\n' +
    '弊社での確定申告サポートがご入用の場合には、\n' +
    '下記ボタンをタップしご依頼くださいませ。',
  inform_tax_2: 'もし、確定申告を依頼する場合は、下記ボタンをタップしてください。',
  undertake_final_tax: '{financial_year}年度({financial_year_ja})の確定申告サポートをご希望の方は下記「依頼す る」ボタンをタップしご依頼ください。\n確定申告サポートが不要の場合は「依頼しない」のタップをお願い致しま す。',
  prepared: '{year}年{month}月{day}日までに準備',
  back_to_final_tax: '確定申告へ戻る',
  unread: '未読',
  mark_read: '全て既読にする',
  read: '既読',
  final_year: '{final_year}年度({final_year_ja})の確定申告ステータス',
  has_error: 'エラーが発生しました。再度お試しください。',
  has_error_network: 'ネットワークが安定している環境で、再度お試しください',
  error_account_register: 'そのアカウントは登録されていました。他のアカウントでお試しください。',
  no_tax: '現在選択できる確定申告がありません',
  otp_desc: 'アカウント認証の有効期限は受付から1時間です。'
}
