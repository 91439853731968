<template>
  <div class="change-email-success">
    <div class="page-body">
      <div class="change-email-success__icon">
        <img
          src="/images/icons/icon-feather-mail.svg"
          alt="Email icon"
          width="31px"
        >
      </div>
      <div
        class="change-email-success__text"
        v-html="nl2br($t('desc_completed_reset_email'))"
      />
    </div>
    <div class="page-footer">
      <div class="change-email-success__return">
        <button
          type="button"
          class="btn btn-primary"
          @click="pushToPage"
        >
          {{ isChangeEmailForTax ? $t('back_to_final_tax') : $t('nav_return_my_menu') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import * as EmailChangeType from '@/enums/email-change-type.enum'
import {nl2br} from '@/utils/string.util'

export default {
  name: 'ChangeEmailSuccess',
  mixins: [WebviewMixin],
  computed: {
    isChangeEmailForTax () {
      const type = this.$route.query.type
      return type === EmailChangeType.TAX ||
        type === EmailChangeType.TAX.toString()
    }
  },
  methods: {
    nl2br,
    pushToPage () {
      if (this.isChangeEmailForTax) {
        this.$router.push({name: this.Page.TaxSession})
      } else {
        this.$router.push({name: this.Page.UserMyMenu})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.change-email-success {
  font-size: 14px;
  text-align: center;
  > *:not(.page-body):not(.page-footer) {
    display: none;
  }

  .page-body {
    padding-top: 70px;
  }

  &__icon {
    margin-bottom: 39px;
    text-align: center;
  }

  &__text {
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
  }

  &__return {
    margin-bottom: 40px;
  }
}
</style>
