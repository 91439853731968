import {createAppInfo} from '@/models/app-info.model'
import {ConfigService} from '@/services/config.service'

const state = () => ({
  appInfo: {},
  appConfig: {},
  platform: null,
})

const getters = {
  /**
   * @returns {Readonly<AppInfoModel>}
   */
  appInfo: state => createAppInfo(state.appInfo || {}),
  appConfig: state => state.appConfig || {},

  platform: state => state.platform,
  hasPlatform: state => !!state.platform,
}

const mutations = {
  setAppInfo (state, appInfo) {
    state.appInfo = appInfo
  },
  setAppConfig (state, appConfig) {
    state.appConfig = appConfig
  },
  updateAppConfig (state, appConfig) {
    state.appConfig = {
      ...state.appConfig,
      ...(appConfig || {})
    }
  },
  setPlatform (state, platform) {
    state.platform = platform
  }
}

const actions = {
  async getAppGeneralInfo ({commit}) {
    try {
      let {data: appInfo} = await ConfigService.getAppGeneralInfo()
      commit('setAppInfo', appInfo)
    } catch (e) {
      throw e
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
