import {ApiService} from './api.service'
import {dynamicSort} from '@/utils/common.util'

export const TaxService = {
  getTaxSessions () {
    return ApiService.get('/tax/sessions')
      .then(res => res.data.data.sort(dynamicSort('desc', 'financial_year', 'id')))
  },

  registerSession (id, isTrusted) {
    return ApiService.post(`/tax/session/${id}/register`, {confirm: isTrusted})
      .then(res => res.data.data)
  },

  getDocumentSession (id) {
    return ApiService.get(`/tax/session/${id}/documents`)
      .then(res => res.data.data)
  },

  checkDocumentSession (id, body) {
    return ApiService.put(`/tax/session/${id}/check`, body)
      .then(res => res.data.data)
  },

  checkAllDocumentSession (id, body) {
    return ApiService.put(`/tax/session/${id}/mark_all`, body)
      .then(res => res.data.data)
  },

  finishedDocumentSession (id) {
    return ApiService.put(`/tax/session/${id}/finished`)
      .then(res => res.data.data)
  },

  getStatusSession (id) {
    return ApiService.get(`/tax/session/${id}/statuses`)
      .then(res => res.data.data)
  },

  getStatusComment (id) {
    return ApiService.get(`/tax/session/${id}/status_comment`)
      .then(res => res.data.data)
  }
}
