import Cookie from 'js-cookie'
import {UserService} from '@/services/user.service'
import { Login } from '@/enums/page-name.enum'
import { getUrlQueries } from '@/utils/string.util'
import router from '@/router'

const hasPlatform = !!getUrlQueries(window.location.href, 'platform')

const state = () => ({
  token: !hasPlatform ? Cookie.get('access_token') : null,
  currentUser: {},
  canResentOtpAt: null
})

const getters = {
  currentUser: state => state.currentUser,
  hasToken: state => !!state.token
}

const mutations = {
  setToken (state, { token }) {
    state.token = token
    if (!hasPlatform) {
      Cookie.set('access_token', token, { expires: 1 })
    }
  },
  clearToken (state) {
    state.token = null
    if (!hasPlatform) {
      Cookie.remove('access_token')
    }
  },
  setCurrentUser (state, user) {
    state.currentUser = user
  }
}

const actions = {
  async getCurrentUserInfo ({commit}) {
    try {
      let {data: userInfo} = await UserService.getUserInfo()
      commit('setCurrentUser', userInfo)
    } catch (e) {
      throw e
    }
  },
  async logout ({commit}) {
    commit('clearToken')
    commit('setCurrentUser', {})
    router.push({name: Login})
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
