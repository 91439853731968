<template>
  <div class="change-email-otp">
    <div class="page-body">
      <div class="change-email-otp__icon">
        <img
          src="/images/icons/icon-simple-minutemailer.svg"
          alt="Email sent icon"
          width="43px"
        >
      </div>
      <div class="change-email-otp__text">
        <div class="change-email-otp__text__email">
          "{{ email }}"
        </div>
        <div
          class="change-email-otp__text__email-sent"
          v-html="nl2div($t('notice_otp_email_sent'))"
        />
      </div>
      <div class="change-email-otp__input">
        <otp-input
          ref="otpInput"
          class="change-email-otp__input__input-wrapper"
          :class="{'is-invalid': form.errors.otp}"
          input-classes="otp-input"
          separator=""
          :num-inputs="6"
          :is-input-num="true"
          @on-change="form.otp = $event"
          @on-complete="form.otp = $event"
        />
        <div class="invalid-feedback">
          {{ form.errors.otp && form.errors.otp[0] }}
        </div>
        <div class="description">
          {{ $t('otp_desc') }}
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="change-email-otp__submit">
        <button
          class="btn btn-primary"
          :disabled="!canSubmit"
          @click="submit"
        >
          {{ $t('common_ok') }}
        </button>
      </div>
      <div class="change-email-otp__resent">
        <button
          v-if="resend.countDown <= 0"
          class="btn-text"
          @click="resendOTP"
        >
          {{ $t('common_resend_otp_email') }}
        </button>
        <button
          v-else
          class="btn-text"
        >
          {{ $tc('common_resend_otp_after_seconds', resend.countDown, {seconds: resend.countDown}) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import OtpInput from '@bachdgvn/vue-otp-input'
import WebviewMixin from '@/mixins/webview.mixin'
import {mapGetters} from 'vuex'
import {RequestError} from '@/errors/request.error'
import {HTTP_UNAUTHORIZED} from '@/enums/http-code.enum'
import {showToast, triggerMobileAction} from '@/utils/webview.util'
import {UserService} from '@/services/user.service'
import {TOKEN_NEW} from '@/enums/mobile-event.enum'
import {nl2div} from '@/utils/string.util'

export default {
  name: 'RegisterEmailOtp',
  components: {
    OtpInput
  },
  mixins: [WebviewMixin],
  data () {
    return {
      webview_scrollToInputOnFocused: true,
      webview_scrollToInputOffset: 10,
      form: {
        otp: null,
        errors: {}
      },
      resend: {
        countDown: 1,
        countInterval: null
      }
    }
  },
  computed: {
    canSubmit () {
      return this.hasToken &&
        this.form.otp && this.form.otp.length === 6 &&
        !this.loading
    },
    ...mapGetters({
      hasToken: 'auth/hasToken',
      email: 'user/tempNewEmail',
      loading: 'loadingAny'
    })
  },
  mounted () {
    if (!this.hasToken) {
      return this.$router.replace({name: this.Page.ChangeEmail})
    }
    this.countDownResend(process.env.VUE_APP_RESEND_OTP_COOLDOWN_SECONDS)
  },
  methods: {
    nl2div,
    countDownResend (seconds) {
      this.resend.countDown = seconds
      this.resend.countInterval = setInterval(() => {
        this.resend.countDown--
        if (this.resend.countDown <= 0) {
          clearInterval(this.resend.countInterval)
          this.resend.countInterval = null
        }
      }, 1000)
    },
    async submit () {
      this.form.errors = {}

      // send request
      let submitOtpResult

      try {
        submitOtpResult = await UserService.verifyOTP(this.form)
      } catch (e) {
        if (e instanceof RequestError) {
          // Show form error
          this.form.errors = e.details
          if (!e.details.otp) {
            this.form.errors = {
              otp: [e.message]
            }
            return
          }
        }
        throw e
      }

      // Set new token for later requests
      this.$store.commit('auth/setToken', submitOtpResult.data)

      // Send new token to mobile
      if (submitOtpResult.data?.token) {
        triggerMobileAction(TOKEN_NEW, {
          token: submitOtpResult.data.token
        })
      }

      await this.$router.replace({name: this.Page.ChangeEmailSuccess, query: {...this.$route.query}})
    },
    async resendOTP () {
      // prevent request to be duplicated
      if (this.resend.isLoading) return

      this.resend.isLoading = true

      // send request
      let resendResult

      try {
        resendResult = await UserService.resendNewEmailOtp()
      } catch (e) {
        // Allow user to resend
        this.resend.isLoading = false

        // Redirect to Login if the key is expired
        if (e instanceof RequestError && e.status === HTTP_UNAUTHORIZED) {
          showToast(e.message)
          await this.$router.push({name: this.Page.Register})
          return
        }

        throw e
      }

      // Allow user to resend
      this.resend.isLoading = false

      // Set new token for later requests
      this.$store.commit('auth/setToken', resendResult.data)

      // Send new token to mobile
      if (resendResult.data?.token) {
        triggerMobileAction(TOKEN_NEW, {
          token: resendResult.data.token
        })
      }

      // Set countdown for this resend function
      this.countDownResend(process.env.VUE_APP_RESEND_OTP_COOLDOWN_SECONDS)

      // Allow user to resend
      this.resend.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.change-email-otp {
  font-size: 14px;
  text-align: center;

  .page-body {
    padding-top: 69px;
  }

  &__icon {
    margin-bottom: 30px;
  }

  &__text {
    margin-bottom: 53px;

    &__email {
      font-size: 20px;
      margin-bottom: 24px;
      word-wrap: break-word;
      white-space: -moz-pre-wrap;
      white-space: pre-wrap;
    }

    &__email-sent {
      color: $text-gray-bold;
    }
  }

  &__input {
    margin-bottom: 47px;

    &__input-wrapper {
      justify-content: center;
    }
  }

  &__submit {
    margin-bottom: 29px;

    .btn {
      margin-bottom: 10px;
    }
  }

  &__resent {
    margin-bottom: 52px;

    a {
      text-decoration: none;

      &, &:hover {
        color: #0AA9F4;
      }
    }
  }
  .description {
    margin-top: 10%;
    margin-bottom: 10%;
    font-size: 3.233vw;
  }
}
</style>
