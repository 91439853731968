<template>
  <div class="tax-list">
    <div class="page-body">
      <template v-if="data.length">
        <ul class="tax-list__sessions">
          <li
            v-for="item in data"
            :key="item.id"
            class="tax-list__sessions__item"
            @click="handleEvent(item)"
          >
            {{ $t('desc_annual_tax_return', {content: item.financial_year, final_year: item.financial_year_ja}) }}
          </li>
        </ul>
      </template>
      <template v-else-if="initialized">
        <p class="tax-list__no-tax">
          {{ $t('no_tax') }}
        </p>
      </template>
    </div>
    <div class="page-footer">
      <div class="tax-list__submit">
        <button
          class="btn btn-primary"
          :disabled="!canSubmit.length"
          @click="handleSubmit"
          v-text="nl2br($t('tax_button'))"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {addEventListener, removeEventListener, triggerMobileAction} from '@/utils/webview.util'
import {USER_TOKEN} from '@/enums/web-event.enum'
import {mapGetters} from 'vuex'
import {filter} from 'lodash-es'
import {nl2br} from '@/utils/string.util'
import {TaxService} from '@/services/tax.service'
import * as Page from '@/enums/page-name.enum'
import moment from 'moment'
import * as ETaxStatusWeight from '@/enums/tax-status-weight.enum'
import {CONFIRM_CONTACT_BROWSER} from '@/enums/mobile-event.enum'

export default {
  name: 'TaxSession',
  mixins: [WebviewMixin],
  data () {
    return {
      data: [],
      initialized: false,
    }
  },
  computed: {
    canSubmit () {
      const currentYear = moment().year()
      return filter(this.data, item => item.financial_year === currentYear)
    },
    ...mapGetters({
      appInfo: 'config/appInfo',
      currentUser: 'auth/currentUser',
    })
  },
  created () {
    addEventListener(USER_TOKEN, this.initData)
  },
  beforeDestroy () {
    removeEventListener(USER_TOKEN, this.initData)
  },
  methods: {
    nl2br,
    initData (e) {
      if (e?.detail?.token) {
        TaxService.getTaxSessions().then(res => {
          this.data = res
        }).finally(() => {
          this.initialized = true
        })
        this.$store.dispatch('config/getAppGeneralInfo')
        this.$store.dispatch('auth/getCurrentUserInfo')
      }
    },
    handleSubmit () {
      // has tax in current year and pick session latest
      if (this.canSubmit.length) {
        this.handleEvent(this.canSubmit[0])
      }
    },
    handleEvent (item) {
      const currentDate = moment()
      const receivingFromDate = moment(item.receiving_from_date)
      const receivingToDate = moment(item.receiving_to_date)

      // current_date < receiving_from_date or current_date > receiving_to_date
      if (currentDate.isBefore(receivingFromDate, 'day') || currentDate.isAfter(receivingToDate, 'day')) {
        // status not exists or status = 1
        if ([
          ETaxStatusWeight.NO_ACTION,
          ETaxStatusWeight.NO_TAX_RETURN,
          ETaxStatusWeight.DOCUMENT_PREPARING,
        ].includes(item.status)) {
          triggerMobileAction(CONFIRM_CONTACT_BROWSER, {
            url: this.appInfo.contact_us
          })
        } else {
          this.routerPush(Page.TaxProgress, item)
        }
      } else if (item.status === ETaxStatusWeight.NO_ACTION) {
        // status is not exists
        if (!this.currentUser.hasEmail) {
          // if use don't have any email, redirect to email provide notice route
          this.$router.push({
            name: Page.TaxEmailProvideNotice,
          })
        } else {
          this.routerPush(Page.TaxTrustingConfirmation, item)
        }
      } else if (item.status === ETaxStatusWeight.NO_TAX_RETURN) {
        this.routerPush(Page.TaxTrustingConfirmationForce, item)
      } else if (item.status === ETaxStatusWeight.DOCUMENT_PREPARING) {
        this.routerPush(Page.TaxDocumentChecking, item)
      } else {
        this.routerPush(Page.TaxProgress, item)
      }
    },
    routerPush (name, item) {
      this.$router.push({
        name,
        params: {id: item.id},
        query: {
          financial_year: item.financial_year,
          financial_year_ja: item.financial_year_ja,
          deadline_date: item.deadline_date
        }
      })
    },
  }
}
</script>

<style
  scoped
  lang="scss"
>

.tax-list {
  font-size: 14px;
  padding: 0;

  &__no-tax{
    text-align: center;
    padding-top: 20%;
  }

  &__sessions {
    font-size: 16px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding-bottom: 20px;

    &__item {
      color: #555555;
      min-height: 60px;
      background-color: #F8F8F8;
      padding: 0 40px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
    }
  }

  .page-footer {
    margin-left: 20px;
    margin-right: 20px;
  }

  &__submit {
    padding-bottom: 8px;
  }

  .btn {
    border-radius: 5px;
  }
}
</style>
