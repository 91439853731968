// Scss
import './assets/scss/main.scss'

// Import Core
import Vue from 'vue'
import App from './views/layouts/Webview.vue'
import router from './router'
import store from './store'
import i18n from '@/plugins/i18n'
import {provide as providePageName} from '@/providers/page-name.provider'
import {preloadImages} from '@/config/preload-images'

import ErrorHandler from '@/errors/handler'

// import filter
import './filter'

Vue.config.productionTip = false

// preload images
preloadImages()

// Handle all Vue errors
Vue.config.errorHandler = (error) => ErrorHandler.onError(error)
ErrorHandler.initHandler()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  provide: {
    ...providePageName()
  },
}).$mount('#app')
