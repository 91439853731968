export const Login = 'Login'
export const LoginCustomerCode = 'LoginCustomerCode'

export const Register = 'Register'
export const RegisterOtp = 'RegisterOtp'
export const RegisterPassword = 'RegisterPassword'
export const RegisterCustomerCode = 'RegisterCustomerCode'

export const ForgotPassword = 'ForgotPassword'
export const ForgotPasswordSent = 'ForgotPasswordSent'

export const GraphSimulation = 'GraphSimulation'
export const GraphCashFlow = 'GraphCashFlow'

export const UserMyMenu = 'UserMyMenu'
export const UserDeactivation = 'UserDeactivation'

export const UserChangeInfo = 'UserChangeInfo'

export const ChangeEmail = 'ChangeEmail'
export const ChangeEmailEmail = 'ChangeEmailEmail'
export const ChangeEmailOtp = 'ChangeEmailOtp'
export const ChangeEmailSuccess = 'ChangeEmailSuccess'

export const ChangePassword = 'ChangePassword'
export const ChangePasswordNew = 'ChangePasswordNew'
export const ChangePasswordSuccess = 'ChangePasswordSuccess'

export const ConfigSecurity = 'ConfigSecurity'
export const ConfigFontSize = 'ConfigFontSize'
export const ConfigPushNotification = 'ConfigPushNotification'

export const Notification = 'Notification'

export const TaxSession = 'TaxSession'
export const TaxEmailProvideNotice = 'TaxEmailProvideNotice'
export const TaxTrustingConfirmation = 'TaxTrustingConfirmation'
export const TaxTrustingConfirmationForce = 'TaxTrustingConfirmationForce'
export const TaxDocumentChecking = 'TaxDocumentChecking'
export const TaxProgress = 'TaxProgress'
