<template>
  <div class="config-push-notification">
    <div class="page-body">
      <div class="config-push-notification__title">
        {{ $t('common_notification') }}
      </div>
      <div class="config-push-notification__input">
        <div class="config-push-notification__input__text">
          <div class="config-push-notification__input__text__section">
            {{ $t('common_enable_notifications') }}
          </div>
        </div>
        <div class="config-push-notification__input__toggle">
          <toggle-button
            v-model="form.enablePushNotification"
            :sync="true"
            :color="{checked: '#24CFD6', unchecked: '#D3D3D3', disabled: '#EAEAEA'}"
            :disabled="!enable"
            :width="40"
            :height="25"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {ToggleButton} from 'vue-js-toggle-button'
import {addEventListener, removeEventListener, triggerMobileAction} from '@/utils/webview.util'
import {DEVICE_CONFIG} from '@/enums/web-event.enum'
import {UPDATE_CONFIG} from '@/enums/mobile-event.enum'

export default {
  name: 'ConfigPushNotification',
  components: {ToggleButton},
  mixins: [WebviewMixin],
  data () {
    return {
      enable: false,
      form: {
        enablePushNotification: false
      }
    }
  },
  watch: {
    'form.enablePushNotification' (val) {
      if (this.enable) {
        triggerMobileAction(UPDATE_CONFIG, {
          enablePushNotification: val
        })
      }
    }
  },
  created () {
    addEventListener(DEVICE_CONFIG, this.enableInput)
  },
  beforeDestroy () {
    removeEventListener(DEVICE_CONFIG, this.enableInput)
  },
  methods: {
    enableInput (event) {
      console.log(event.detail)
      if (event && event.detail) {
        this.form.enablePushNotification = event.detail.enable_push_notification
      }
      this.enable = true
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
.config-push-notification {
  font-size: 14px;
  .page-body {
    padding-top: 17px;
  }

  &__title {
    padding-bottom: 19px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 17px;
    font-size: 16px;
    font-weight: bold;
  }

  &__input {
    display: flex;

    &__text {
      white-space: nowrap;

      &__section {
        margin-bottom: 2px;
        line-height: 19px;
      }

      &__desc {
        color: #777777;
        font-size: 11px;
        line-height: 15px;
      }
    }
    &__toggle {
      width: 100%;
      text-align: right;
      padding-top: 6px;
    }
  }
}
</style>
