<template>
  <div class="wrapper-simulation">
    <!-- MAIN CHART CONTENT -->
    <div
      class="chart_content simulation"
    >
      <apexchart
        ref="updateRealtime"
        :options="chartOptions"
        :series="series"
      />
    </div>

    <!-- LEGEND BLOCK -->
    <div
      class="chart_legend chart_legend_right"
    >
      <div
        v-for="(item, idx) in series"
        :key="item.name"
        class="chart_legend_item"
      >
        <svg
          :width="dataLegends.markers.width"
          :height="item.type === 'line' ? dataLegends.markers.height / 2 : dataLegends.markers.height"
        >
          <line
            v-if="item.type === 'line'"
            x1="0"
            y1="0"
            :x2="dataLegends.markers.width"
            y2="0"
            :style="{'stroke':chartOptions.colors[idx],'stroke-width': '5', 'stroke-dasharray': chartOptions.stroke.dashArray[idx]}"
          />
          <rect
            v-else
            :width="dataLegends.markers.width"
            :height="dataLegends.markers.height"
            :fill="chartOptions.colors[idx]"
          />
        </svg>
        <span>{{ item.name }}</span>
      </div>
    </div>

    <!-- DATA BLOCK -->
    <div class="chart_info">
      <div
        v-if="dataCharIndex !== null"
        class="chart_data"
      >
        <span
          v-for="item in series"
          :key="item.name"
          class="data_item_info"
        >
          {{ item.name + ': ' + ((item.data[dataCharIndex] + barOffset) * 10).toLocaleString() + ' 万' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'

function formatYLabel (val, barOffset) {
  let multiple = Math.abs(val) <= Math.abs(barOffset) ? -1 : 1
  let data = (Math.round(Math.abs((val + barOffset)) / 100) * 1000)

  return parseInt(multiple * data).toLocaleString() + ' 万'
}

export default {
  name: 'GraphSimulation',
  mixins: [WebviewMixin],
  props: {
    dataLabels: {
      type: Array,
      default: () => {
        return [41, 46, 51, 56, 61, 66, 71, 76, 81, 86, 91]
      }
    },
    dataSeries: {
      type: Array,
      default: () => {
        return [
          {
            name: '損益',
            type: 'bar',
            data: [-304, 332, 336, 381, 295, 312, 457, 409, 272, 241, 194]
          },
          {
            name: '売却予想',
            type: 'line',
            data: [404, 382, 366, 351, 345, 332, 327, 309, 282, 261, 244]
          },
          {
            name: '支払い残高',
            type: 'line',
            data: [364, 402, 426, 411, 455, 412, 397, 369, 322, 391, 424]
          }
        ]
      }
    },
    dataColors: {
      type: Array,
      default: () => {
        return []
      }
    },
    dataYearOld: { // this props is REQUIRED for `simulation` chart
      type: Number,
      default: 64
    },
    dataLegends: {
      type: Object,
      default: () => {
        return {
          markers: {
            width: 12,
            height: 12
          },
          stroke: {
            width: [0, 2, 2],
            curve: 'straight',
            dashArray: [0, 0, [3, 2]]
          }
        }
      }
    },
    dataStates: {
      type: Object,
      default: () => {
        return {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        }
      }
    },
    dataMonth: { // this props is REQUIRED for `cash_flow` chart
      type: Number,
      default: 0
    },
    isStacked: {
      type: Boolean,
      default: false
    },
    showAnnotation: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      barOffset: 0,
      maxOffset: 0,
      minOffset: 0,
      dataCharIndex: null,
      selectMonth: this.$props.dataMonth,
      chartOptions: {
        colors: ['#FD562166', '#0AA9F4', '#D35BDD'], // add/edit chart color, can custom this
        chart: {
          id: 'trusty_chart_exp',
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          stacked: this.$props.isStacked
        },
        labels: this.$props.dataLabels,
        states: this.$props.dataStates,
        plotOptions: {
          bar: {
            columnWidth: '55%'
          }
        },
        tooltip: {
          enabled: false,
          shared: true
        },
        legend: {
          show: false
        },
        stroke: {
          width: 0,
          curve: 'straight',
          dashArray: [0, 0, 2.5]
        },
        markers: {
          strokeWidth: 0,
          size: [0, 0, 2] // width of marker
        },
        xaxis: {
          type: 'number',
          tickAmount: 5,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return formatYLabel(val, this.barOffset)
            }
          }
        },
        grid: {
          show: false,
          padding: {
            left: -2
          }
        },
        annotations: {
          position: 'back'
        },
        responsive: [
          {
            breakpoint: 414,
            options: {
              yaxis: {
                min: 0,
                tickAmount: 4,
                labels: {
                  formatter: (val) => {
                    return formatYLabel(val, this.barOffset)
                  },
                  style: {
                    fontSize: '10px'
                  }
                }
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: '10px'
                  }
                }
              },
              stroke: {
                width: [0, 1.5, 1.5],
                curve: 'straight',
                dashArray: [0, 0, [4, 1]]
              },
              markers: {
                strokeWidth: 0,
                size: [0, 0, 0] // width of marker
              }
            }
          },
          {
            breakpoint: 768,
            options: {
              yaxis: {
                min: 0,
                tickAmount: 4,
                labels: {
                  formatter: (val) => {
                    return formatYLabel(val, this.barOffset)
                  },
                  style: {
                    fontSize: '12px'
                  }
                }
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: '12px'
                  }
                }
              },
              stroke: {
                width: [0, 2, 2],
                curve: 'straight',
                dashArray: [0, 0, [4, 1]]
              },
              markers: {
                strokeWidth: 0,
                size: [0, 0, 0] // width of marker
              }
            }
          },
          {
            breakpoint: 992,
            options: {
              yaxis: {
                min: 0,
                tickAmount: 4,
                labels: {
                  formatter: function (val) {
                    return formatYLabel(val, this.barOffset)
                  },
                  style: {
                    fontSize: '14px'
                  }
                }
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: '14px'
                  }
                }
              },
              stroke: {
                width: [0, 2.5, 2.5],
                curve: 'straight',
                dashArray: [0, 0, [5, 3]]
              },
              markers: {
                strokeWidth: 0,
                size: [0, 0, 0] // width of marker
              }
            }
          }]
      },
      series: this.$props.dataSeries
    }
  },

  created () {
    // calculate bar offset
    this.series.forEach((series) => {
      let maxData = Math.ceil(Math.max(...series.data) * 1.3)
      let minData = Math.floor(Math.min(...series.data) * 1.3)

      this.minOffset = this.minOffset > minData ? minData : this.minOffset
      this.maxOffset = this.maxOffset < maxData ? maxData : this.maxOffset
    })

    let maxRange = Math.abs(this.minOffset) > Math.abs(this.maxOffset) ? Math.abs(this.minOffset) : Math.abs(this.maxOffset)
    let tickAmount = 3
    maxRange = (Math.round(maxRange / 10) + 1) * 10
    if (this.maxOffset > 0) {
      if (this.minOffset < 0) {
        this.barOffset = -maxRange
        maxRange *= 2
        tickAmount = 4
      } else {
        this.barOffset = 0
      }
    } else {
      this.barOffset = -maxRange
    }

    this.chartOptions.responsive.forEach(item => {
      item.options.yaxis.tickAmount = tickAmount
      item.options.yaxis.max = maxRange
    })

    // minus every data for barOffset
    this.series = this.dataSeries.map((series) => {
      let newData = {...series}

      newData.data = newData.data.map((data) => {
        // barOffset always < 0
        return data - this.barOffset
      })

      return newData
    })

    const self = this
    this.chartOptions.chart.events = {
      click: () => {
        let chartRect = document.querySelector('.apexcharts-inner.apexcharts-graphical').getBoundingClientRect()
        let clickPosition = (event.clientX ? event.clientX : event.changedTouches[0]?.clientX) - chartRect.x
        let singleBarWidth = chartRect.width / self.chartOptions.labels.length

        // get chart index based on click position
        let dataPointIndex = Math.floor(clickPosition / singleBarWidth)

        self.dataCharIndex = dataPointIndex < 0 ? 0 : (dataPointIndex >= self.chartOptions.labels.length ? self.chartOptions.labels.length - 1 : dataPointIndex)
        self.updateAnnotation(self.chartOptions.labels[dataPointIndex])
      }
    }
  },

  mounted () {
    // find nearest TRUE data of `dataYearOld` for drawing the Annotation
    // example: `dataYearOld` = 43 => closet = 41
    //          `dataYearOld` = 44 => closet = 46
    const closet = this.chartOptions.labels.reduce((a, b) => {
      return Math.abs(b - this.dataYearOld) < Math.abs(a - this.dataYearOld) ? b : a
    })

    this.dataCharIndex = this.chartOptions.labels.indexOf(closet)
    this.updateAnnotation(closet)
  },

  methods: {
    updateAnnotation (x) {
      if (this.showAnnotation) {
        this.$refs.updateRealtime.clearAnnotations()
        this.$refs.updateRealtime.addXaxisAnnotation({
          strokeDashArray: 0,
          x: x,
          opacity: 0,
          offsetY: -5,
          label: {
            borderWidth: 0,
            text: '〇',
            textAnchor: 'middle',
            position: 'top',
            orientation: 'horizon',
            offsetY: -9,
            style: {
              color: '#c2c2c2',
              fontSize: '16px',
              fontWeight: 900
            }
          }
        }, false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/page/_simulation-graph.scss";
</style>
