<template>
  <div class="change-password-new">
    <div class="page-body">
      <div class="change-password-new__input">
        <div>
          <div
            class="relative"
            :class="{'is-invalid': form.errors && form.errors.password}"
          >
            <input
              ref="passwordInputEl"
              v-model="form.password"
              :type="form.options.showPassword ? 'text' : 'password'"
              :class="{'is-invalid': form.errors && form.errors.password}"
              :placeholder="$t('common_new_password')"
            >
            <template>
              <img
                v-if="form.options.showPassword"
                src="/images/icons/icon-feather-eye.svg"
                width="14px"
                alt="Show password"
                @click="form.options.showPassword = false, $refs.passwordInputEl.focus()"
              >
              <img
                v-else
                src="/images/icons/icon-feather-eye-off.svg"
                width="14px"
                alt="Hide password"
                @click="form.options.showPassword = true, $refs.passwordInputEl.focus()"
              >
            </template>
          </div>
          <div
            class="invalid-feedback"
            v-html="form.errors && form.errors.password && nl2br(form.errors.password[0])"
          />
        </div>
        <div>
          <div
            class="relative"
            :class="{'is-invalid': form.errors && form.errors.passwordConfirmation}"
          >
            <input
              ref="passwordConfirmInputEl"
              v-model="form.passwordConfirmation"
              :type="form.options.showPasswordConfirmation ? 'text' : 'password'"
              :class="{'is-invalid': form.errors && form.errors.passwordConfirmation}"
              :placeholder="$t('common_confirm')"
            >
            <template>
              <img
                v-if="form.options.showPasswordConfirmation"
                src="/images/icons/icon-feather-eye.svg"
                width="14px"
                alt="Show password"
                @click="form.options.showPasswordConfirmation = false, $refs.passwordConfirmInputEl.focus()"
              >
              <img
                v-else
                src="/images/icons/icon-feather-eye-off.svg"
                width="14px"
                alt="Hide password"
                @click="form.options.showPasswordConfirmation = true, $refs.passwordConfirmInputEl.focus()"
              >
            </template>
          </div>
          <div
            class="invalid-feedback"
            v-html="form.errors && form.errors.passwordConfirmation && nl2br(form.errors.passwordConfirmation[0])"
          />
        </div>
        <div class="change-password-new__input__password-rule">
          {{ $t('common_password_rule_desc') }}
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="change-password-new__submit">
        <button
          class="btn btn-outline-primary"
          :disabled="!validated"
          @click="submit"
        >
          {{ $t('common_save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {validatePassword, validatePasswordConfirm} from '@/utils/validate.util'
import {RequestError} from '@/errors/request.error'
import {UserService} from '@/services/user.service'
import {nl2br} from '@/utils/string.util'
import * as Page from '@/enums/page-name.enum'

export default {
  name: 'ChangePasswordNew',
  mixins: [WebviewMixin],
  data () {
    return {
      form: {
        password: null,
        passwordConfirmation: null,
        options: {
          showPassword: false,
          showPasswordConfirmation: false
        },
        errors: {}
      }
    }
  },
  computed: {
    validated () {
      return this.form.password && this.form.passwordConfirmation
    }
  },

  beforeRouteEnter (to, from, next) {
    switch (from.name) {
      case Page.ChangePasswordSuccess:
        next({name: Page.ChangePassword})
        return
    }
    next()
  },
  methods: {
    nl2br,
    validate () {
      this.form.errors = {}

      let passwordError = validatePassword(this.form.password)
      if (passwordError) {
        this.form.errors.password = [passwordError]
      }

      let passwordConfirmError = validatePasswordConfirm(this.form.password, this.form.passwordConfirmation)
      if (passwordConfirmError) {
        this.form.errors.passwordConfirmation = [passwordConfirmError]
      }

      return !Object.keys(this.form.errors).length
    },
    async submit () {
      if (!this.validate()) {
        return
      }

      // send request
      try {
        await UserService.saveNewPassword(this.form)
      } catch (e) {
        if (e instanceof RequestError) {
          // Show form error
          this.form.errors = e.details
          if (!e.details.password) {
            this.form.errors = {
              password: [e.message]
            }
            return
          }
        }
        throw e
      }

      this.$router.replace({name: this.Page.ChangePasswordSuccess})
    }
  }
}
</script>

<style scoped lang="scss">
.change-password-new {
  font-size: 14px;

  .page-body {
    padding-top: 40px;
  }

  &__input {
    margin-bottom: 50px;
    input {
      height: 32px;
      padding: 0 25px 0 5px;
    }

    img {
      position: absolute;
      right: 7px;
      top: 9px;
    }

    div:not(:last-child) {
      margin-bottom: 16px;
    }

    &__password-rule {
      font-size: 12px;
      color: $text-gray;
      text-align: right;
      padding: 0 10px;
    }
  }

  &__submit {
    margin-bottom: 40px;
  }
}
</style>
