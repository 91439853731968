<template>
  <div class="user-change-info">
    <div class="page-body">
      <div>
        <div
          class="user-change-info__input"
          :class="{'is-invalid': form.errors && form.errors.fullname}"
        >
          <label for="input-user-name">
            {{ $t('common_fullname') }}
          </label>
          <input
            id="input-user-name"
            v-model="form.fullname"
            :placeholder="currentUser.fullname"
            type="text"
            readonly
            :class="{'is-invalid': form.errors && form.errors.fullname}"
          >
          <div class="user-change-info__input__border" />
        </div>
        <div
          class="invalid-feedback"
          v-html="form.errors && form.errors.fullname && form.errors.fullname[0]"
        />
      </div>
      <div
        v-if="currentUser.canChangeEmail"
        class="user-change-info__input user-change-info__input--email"
        @click="confirmChangeEmail"
      >
        <label>{{ $t('common_email_address') }}</label>
        <div class="input-wrapper">
          <input
            :value="currentUser.email || currentUser.email_sns"
            readonly
          >
          <div class="mask" />
        </div>
        <div class="user-change-info__input__arrow">
          <img
            src="/images/icons/icon-arrow-right-gray.svg"
            height="18px"
            width="18px"
          >
        </div>
        <div class="user-change-info__input__border" />
      </div>
      <router-link
        v-if="currentUser.canChangePassword"
        v-slot="{ navigate }"
        :to="{name: Page.ChangePassword}"
        custom
      >
        <div
          class="user-change-info__input"
          @click="navigate"
        >
          <label>{{ $t('common_password') }}</label>
          <div
            class="user-change-info__input__arrow"
            style="width: 100%;"
          >
            <img
              src="/images/icons/icon-arrow-right-gray.svg"
              height="18px"
              width="18px"
              alt=""
            >
          </div>
          <div class="user-change-info__input__border" />
        </div>
      </router-link>
      <div class="user-change-info__input">
        <label>{{ $t('common_customer_number') }}</label>
        <input
          :value="currentUser.customer_code"
          readonly
        >
        <div class="user-change-info__input__border" />
      </div>
      <div
        class="user-change-info__logout"
        @click="confirmLogout"
      >
        {{ $t('common_logout') }}
      </div>
    </div>
    <template v-if="1===0">
      <div class="page-footer">
        <button
          class="btn btn-outline-primary btn-rounded"
          :disabled="!canSubmit"
          @click="submit"
        >
          {{ $t('common_save') }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {addEventListener, removeEventListener, triggerMobileAction} from '@/utils/webview.util'
import {CONFIRMED_CHANGE_EMAIL, USER_TOKEN} from '@/enums/web-event.enum'
import {CONFIRM_CHANGE_EMAIL, CONFIRM_LOGOUT} from '@/enums/mobile-event.enum'
import {mapGetters} from 'vuex'
import {UserService} from '@/services/user.service'
import {RequestError} from '@/errors/request.error'
import {createUser} from '@/models/user.model'
import * as UserRegisterType from '@/enums/user-register-type.enum'

export default {
  name: 'UserChangeInfo',
  mixins: [WebviewMixin],
  data () {
    return {
      form: {
        fullname: '',
        error: {}
      }
    }
  },
  computed: {
    canSubmit () {
      return !this.loading && this.hasToken && this.form.fullname && this.form.fullname !== this.currentUser.fullname
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasToken: 'auth/hasToken',
      loading: 'loadingAny',
      hasPlatform: 'config/hasPlatform',
    })
  },
  watch: {
    currentUser (val) {
      this.form.fullname = val.fullname
    }
  },
  created () {
    addEventListener(CONFIRMED_CHANGE_EMAIL, this.redirectToChangeEmail)
    addEventListener(USER_TOKEN, this.initData)

    this.form.fullname = this.currentUser.fullname || ''
  },
  beforeDestroy () {
    removeEventListener(CONFIRMED_CHANGE_EMAIL, this.redirectToChangeEmail)
    removeEventListener(USER_TOKEN, this.initData)
  },
  methods: {
    initData (e) {
      if (e?.detail?.token) {
        this.$store.dispatch('auth/getCurrentUserInfo')
      }
    },
    confirmChangeEmail () {
      if (this.currentUser.type === UserRegisterType.SNS) {
        this.$router.push({name: this.Page.ChangeEmailEmail})
      } else {
        triggerMobileAction(CONFIRM_CHANGE_EMAIL, {})
      }
    },
    redirectToChangeEmail () {
      this.$router.push({name: this.Page.ChangeEmail})
    },
    confirmLogout () {
      if (this.hasPlatform) {
        triggerMobileAction(CONFIRM_LOGOUT, {})
      } else {
        this.$store.dispatch('auth/logout')
      }
    },
    async submit () {
      // send request
      try {
        await UserService.updateUserInfo(this.form)

        this.$store.commit('auth/setCurrentUser', createUser({...this.currentUser, ...this.form}))
      } catch (e) {
        if (e instanceof RequestError) {
          // Show form error
          this.form.errors = e.details
          if (!e.details.fullname) {
            this.form.errors = {
              fullname: [e.message]
            }
            return
          }
        }
        throw e
      }
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
.user-change-info {
  .page-body {
    margin: 0 -10px 20px;
    width: calc(100% + 20px);
    padding-top: 20px;
  }
  .page-footer {
    padding-bottom: 40px;
  }
  &__input {
    display: flex;
    position: relative;
    padding: 9px 10px;
    img {
      max-width: unset;
    }
    &__border {
      border-bottom: 1px solid $border-light-grey-2;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &--email {
      .input-wrapper {
        position: relative;
        width: 100%;
      }
      .mask {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 90px;
        @include css3-prefix('background', linear-gradient(90deg, rgba(255,255,255,0) 10%, rgba(255,255,255,1) 70%, white 100%));
      }
    }
    &__arrow {
      padding-top: 7px;
      text-align: right;
    }
  }
  label {
    padding-top: 9px;
    padding-bottom: 7px;
    white-space: nowrap;
  }
  input {
    border: none;
    text-align: right;
    padding-right: 0;
    caret-color: $text-blue;
    //&:not(:read-only):focus + .user-change-info__input__border {
    //  border-color: $border-gray !important;
    //}
  }

  &__logout {
    color: $text-blue;
    background-color: $btn-white;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-top: 20px;
    cursor: pointer;
  }
}
</style>
