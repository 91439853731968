const dynamicSort = (order, property_1, property_2 = null) => {
  let sortOrder = 1
  if (order === 'desc') {
    sortOrder = -1
  }

  return function (a, b) {
    // a should come before b in the sorted order
    if (a[property_1] < b[property_1]) {
      return -1 * sortOrder
      // a should come after b in the sorted order
    } else if (a[property_1] > b[property_1]) {
      return sortOrder
      // a and b are the same
    } else {
      if (property_2 && a[property_1] > b[property_2]) {
        return sortOrder
      }
    }
  }
}

export { dynamicSort }
