<template>
  <div
    class="header"
  >
    <div class="header__app">
      <h2 class="header__app-logo">
        <img
          src="/images/logo-horizontal.svg"
          alt="makeA_logo"
        >
      </h2>
      <strong
        class="header__app-title"
        v-text="pageTitle"
      />
    </div>

    <router-link
      v-if="metaStack.routeBack"
      v-slot="{ navigate }"
      :to="{ name: metaStack.routeBack }"
      class="header__navigate"
      custom
    >
      <img
        src="/images/icons/icon-arrow-left.svg"
        width="9px"
        alt="Back"
        @click="navigate"
      >
    </router-link>
  </div>
</template>

<script>
import RouterMetaStack from '@/mixins/router-meta-stack.mxin'

export default {
  name: 'Header',
  mixins: [
    RouterMetaStack
  ],
  computed: {
    pageTitle () {
      return this.$route.meta.title ? this.$t(this.$route.meta.title) : 'TRUSTY APP'
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  padding: 8px 20px;
  &__navigate {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__app {
    text-align: center;
    &-logo {
      width: 110px;
      height: 22px;
      margin: 0 auto 3px;
    }

    &-title {
      display: inline-block;
      color: var(--color-header-title);
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
