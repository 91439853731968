import { AuthService } from '@/services/auth.service'
import ErrorHandler from '@/errors/handler'
import {addEventListener, removeEventListener, triggerMobileAction} from '@/utils/webview.util'
import {LOGIN_WITH_APPLE, PAGE_LOADING} from '@/enums/mobile-event.enum'
import {IOS} from '@/enums/platform'
import {mapGetters} from 'vuex'
import {DEVICE_CONFIG} from '@/enums/web-event.enum'

export default {
  created () {
    addEventListener(DEVICE_CONFIG, this.updateAppConfig)
  },
  beforeDestroy () {
    removeEventListener(DEVICE_CONFIG, this.updateAppConfig)
  },
  computed: {
    ...mapGetters({
      appConfig: 'config/appConfig',
    })
  },
  methods: {
    async appleAuth_init () {
      if (!this.$route.query?.login_with_sns) {
        return
      }

      await this.appleAuth_loginWithPasscode()
    },

    async appleAuth_signInWithApple () {
      // for ios, to turn off loading
      triggerMobileAction(PAGE_LOADING, {
        toggle: false
      })

      // 20221026 only apply triggerMobileAction for newer iOS version than 1.0.1
      const lastIosVersionToTriggerMobileEvent = process.env.VUE_APP_LAST_VERSION_SIGN_IN_WITH_APPLE
      if (this.$route.query.platform === IOS.toString() && this.appConfig.version > lastIosVersionToTriggerMobileEvent) {
        triggerMobileAction(LOGIN_WITH_APPLE)
      } else {
        window.location.href = process.env.VUE_APP_APPLE_OAUTH_URL
      }
    },

    async appleAuth_loginWithPasscode () {
      const query = this.$route.query

      try {
        let loginResult = await AuthService.loginWithSnsPasscode(query)

        this.appleAuth_onAuthenticated(loginResult.data)
      } catch (e) {
        ErrorHandler.triggerError(e.message)
      }
    },

    appleAuth_onAuthenticated (data) {
      // Do something with the response
    },
  },
}
