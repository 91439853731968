import {ApiService} from '@/services/api.service'
import {dynamicSort} from '@/utils/common.util'

export const NotificationService = {
  getNumberOfUnreadMessages () {
    return ApiService.get('/notifications/unread/count', {}, {noLoading: true})
      .then(res => res.data?.data?.total)
  },

  getListNotifyUnread (params = {}) {
    return ApiService.get('/notifications/unread', params)
      .then(res => res.data.data.sort(dynamicSort('desc', 'published_time')))
      .catch(err => err)
  },

  getListNotifyRead (params = {}) {
    return ApiService.get('/notifications/read', params)
      .then(res => res.data.data.sort(dynamicSort('desc', 'published_time')))
      .catch(err => err)
  },

  countNotifyUnread () {
    return ApiService.get('/notifications/unread/count')
      .then(res => res.data?.data?.total)
  },

  checkReadAll () {
    return ApiService.put('/notifications/read_all')
      .then(res => res.data.data)
      .catch(err => err)
  },

  checkRead (id) {
    return ApiService.put(`/notification/${id}`)
      .then(res => res.data.data)
      .catch(err => err)
  }
}
