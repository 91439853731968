<template>
  <div class="change-password-success">
    <div class="page-body">
      <div class="change-password-success__icon">
        <img
          src="/images/icons/icon-feather-lock.svg"
          alt="Password icon"
          width="27px"
        >
      </div>
      <div
        class="change-password-success__text"
        v-html="nl2br($t('desc_completed_reset_password'))"
      />
    </div>
    <div class="page-footer">
      <div class="change-password-success__return">
        <router-link
          v-slot="{ navigate }"
          :to="{name: Page.UserMyMenu}"
          custom
        >
          <button
            class="btn btn-primary"
            @click="navigate"
          >
            {{ $t('nav_return_my_menu') }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import {nl2br} from '@/utils/string.util'

export default {
  name: 'ChangePasswordSuccess',
  mixins: [WebviewMixin],
  methods: {
    nl2br
  }
}
</script>

<style scoped lang="scss">
.change-password-success {
  font-size: 14px;
  text-align: center;

  .page-body {
    padding-top: 68px;
  }

  &__icon {
    margin-bottom: 36px;
    text-align: center;
  }

  &__text {
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
  }

  &__return {
    margin-bottom: 40px;
  }
}
</style>
