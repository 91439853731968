import {ApiService} from './api.service'
import {createAppInfo} from '@/models/app-info.model'

export const ConfigService = {
  getAppGeneralInfo: () => {
    return ApiService.get('/app/general', {}, { noLoading: true })
      .then(res => {
        res.data.data = createAppInfo(res.data?.data)
        return res.data
      })
  },

  getConfig: () => {
    return ApiService.get('/user/config')
      .then(res => res.data)
  },

  saveConfig: ({enable_push_notification}) => {
    let config = {
      enable_push_notification: Number(enable_push_notification)
    }
    return ApiService.put('/user/config', config)
      .then(res => res.data)
  }
}
