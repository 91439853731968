const state = () => ({
  openStatus: {
    support: false,
    config: false,
    information: false,
  }
})

const getters = {
  openStatus: state => state.openStatus
}

const mutations = {
  toggleMenu (state, {groupName, isOpen}) {
    switch (groupName) {
      case 'support':
      case 'config':
      case 'information':
        state.openStatus[groupName] = !!isOpen
        break
    }
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
