import {triggerMobileAction} from '@/utils/webview.util'
import {SHOW_ERROR, SHOW_ERROR_RETRY} from '@/enums/mobile-event.enum'
import {UnexpectedError} from '@/errors/unexpected.error'
import {FirebaseAuthError} from '@/errors/firebase-auth.error'
import i18n from '@/plugins/i18n'

export default class ErrorHandler {
  constructor () {
    this.initHandler()
  }

  static onError (error, canRetry = false) {
    if (window.navigator.onLine) {
      switch (true) {
        case error instanceof FirebaseAuthError:
        case error instanceof UnexpectedError:
          this.triggerError(error.message, canRetry)
          break
      }
    } else {
      this.triggerError(i18n.t('has_error_network'), canRetry)
    }
  }

  static triggerError (message, canRetry = false) {
    triggerMobileAction(canRetry ? SHOW_ERROR_RETRY : SHOW_ERROR, {
      message
    })
  }

  static initHandler () {
    window.onerror = (message, url, lineNo, columnNo, error) => {
      this.onError(new UnexpectedError({message, url, lineNo, columnNo, error}))
    }
  }
}
