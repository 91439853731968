export const NO_ACTION = 0

export const NO_TAX_RETURN = 1

export const DOCUMENT_PREPARING = 2

export const DOCUMENT_COMING = 3

export const DOCUMENT_CHECKING = 4

export const DOCUMENT_APPROVED = 5

export const DOCUMENT_COMPLETED = 6

export const DOCUMENT_FEE_WAITING = 7
